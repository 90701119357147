import React, { useEffect } from "react";

import Deck from "./Deck";
import { DECKS } from "./decks";

import { useAppSelector } from "./app/hooks";

const DeckSwitcher: React.FC = () => {
  const deckName = useAppSelector((state) => state.route.deck);
  const deck = DECKS[deckName];
  const Mdx = deck.default;

  useEffect(() => {
    const head = window.document.head;
    const title = head.getElementsByTagName("title")[0];
    if (!title) {
      return;
    }

    if (!deck.title) {
      return;
    }

    if (!title.dataset["orig"]) {
      title.dataset["orig"] = title.text;
    }
    const originalTitle = title.dataset["orig"];

    title.text = `${originalTitle} | ${deck.title}`;

    return () => {
      title.text = originalTitle;
    };
  }, [deck]);

  return <Deck MdxDocument={Mdx} />;
};

export default DeckSwitcher;
