import { useContext } from "react";
import { useAppSelector } from "./app/hooks";
import { SlidesContext, applyLayout } from "./Deck";

import * as styles from "./Presenter.module.css";

const Presenter: React.FC = () => {
  const slides = useContext(SlidesContext);

  const slideIndex = useAppSelector((state) => state.deck.slideIndex);
  const slide = slides[slideIndex - 1];
  const nextSlide = slides[slideIndex];

  const notes = slide.notes;
  const slideContent = applyLayout(slide);
  const nextSlideContent = applyLayout(nextSlide);

  return (
    <div className={styles.container}>
      <div className={styles.slideCurrent}>{slideContent}</div>
      <h1 className={styles.labelLeft}>Notes</h1>
      <div className={styles.notes}>{notes}</div>
      <h1 className={styles.labelRight}>Next</h1>
      <div className={styles.slideNext}>{nextSlideContent}</div>
      <div className={styles.timer}>A timer or whatever</div>
    </div>
  );
};

export default Presenter;
