.hovered {
  position: relative;
}

.copy {
  position: absolute;
  right: 1em;
  bottom: 1em;
  display: none;
}

.hovered:hover .copy {
  display: block;
}
