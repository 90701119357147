import { configureStore } from "@reduxjs/toolkit";

import { listenerMiddleware } from "./listenerMiddleware";
import buildBroadcastMiddleware from "./buildBroadcastMiddleware";
import buildUrlMiddleware from "./buildUrlMiddleware";

import deckReducer from "../features/deck/deckSlice";
import helpReducer from "../features/help/helpSlice";
import keyboardReducer from "../features/keyboard/keyboardSlice";
import routeReducer from "../features/route/routeSlice";
import windowReducer from "../features/window/windowSlice";

interface buildStoreProps {
  channel: BroadcastChannel;
  window: Window;
}

export const buildStore = ({ channel, window }: buildStoreProps) => {
  buildBroadcastMiddleware(channel);
  buildUrlMiddleware(window);

  return configureStore({
    reducer: {
      deck: deckReducer,
      help: helpReducer,
      keyboard: keyboardReducer,
      route: routeReducer,
      window: windowReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(listenerMiddleware.middleware),
  });
};

export type Store = ReturnType<typeof buildStore>;

export type RootState = ReturnType<Store["getState"]>;
export type AppDispatch = Store["dispatch"];
