import { useContext } from "react";

import { SlidesContext, applyLayout } from "./Deck";
import { useAppSelector } from "./app/hooks";

import * as styles from "./Display.module.css";

const Display: React.FC = () => {
  const slides = useContext(SlidesContext);
  const slideIndex = useAppSelector((state) => state.deck.slideIndex);

  const wrappedSlides = slides.map((s, i) => {
    const className = i === slideIndex - 1 ? styles.container : styles.hidden;
    return (
      <div className={className} key={i}>
        {applyLayout(s)}
      </div>
    );
  });

  return <>{wrappedSlides}</>;
};

export default Display;
