import React from "react";
import { Provider } from "react-redux";

import DeckSwitcher from "./DeckSwitcher";
import ErrorBoundary from "./ErrorBoundary";
import Help from "./Help";
import HtmlStyler from "./HtmlStyler";
import { Store } from "./app/store";

interface AppProps {
  store: Store;
}

const App: React.FC<AppProps> = ({ store }) => (
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <HtmlStyler />
        <DeckSwitcher />
        <Help />
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

export default App;
