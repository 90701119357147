import { startAppListening } from "./listenerMiddleware";
import { broadcast } from "./broadcast";

const buildBroadcastMiddleware = (channel: BroadcastChannel) => {
  startAppListening({
    actionCreator: broadcast,
    effect: async (action, listenerApi) => {
      channel.postMessage(action.payload);

      for (const childAction of action.payload) {
        listenerApi.dispatch(childAction);
      }
    },
  });
};

export default buildBroadcastMiddleware;
