import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";
import Quote from "./Quote";

import {
  FutureExtTrait,
  FutureTrait,
  FuturesAsyncReadExtRead,
  FuturesIoAsyncReadPollRead,
  IteratorTrait,
  OptionEnum,
  PollEnum,
  StreamExtTrait,
  StreamTrait,
  TokioAsyncReadExtRead,
  TokioAsyncReadPollRead,
  TryFutureExtTrait,
  TryFutureTrait,
  TryStreamExtTrait,
  TryStreamTrait,
} from "./Excerpts";
import { ChannelQuote } from "./Quotes";

import * as styles from "./async.module.css";

export const title = "Asynchronous programming";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# Asynchronous programming

---

# What's the point of asynchronous Rust?

<Notes />

- JavaScript, C#, Python
- Future is something that will eventually have a value
- Promise is something that you can complete

---

<SlideLayout use={Splash} />

import singleImg from "./assets/async/single.png";

<img className={styles.fullImage} src={singleImg} alt="A single blocking job" />

---

<SlideLayout use={Splash} />

import threadsImg from "./assets/async/threads.png";

<img className={styles.fullImage} src={threadsImg} alt="Three blocking jobs" />

---

<SlideLayout use={Splash} />

import asyncImg from "./assets/async/async.png";

<img className={styles.fullImage} src={asyncImg} alt="Three jobs, one thread" />

---

# Defining an asynchronous function

import basicSyntax from "rust:async/basic-syntax";

<Transclude src={basicSyntax} focusOn="1+3" />

<Notes />

- An async function doesn't do anything when called.
- Allows the compiler to perform a transformation on the code

---

# Calling an asynchronous function

## From an asynchronous function

<Transclude src={basicSyntax} focusOn="5+3" />

<Notes />

- Only valid inside an `async` function ...

<Continue />

## From an asynchronous block

<Transclude src={basicSyntax} focusOn="9+5" />

<Notes />

- ... or block
- `.await` is a place where control may be yielded to something else.
- Postfix keyword is unique in Rust (so far)

---

# Asynchronous functions and error handling

import errorHandling from "rust:async/error-handling";

<Transclude src={errorHandling} focusOn="1+8" emphasize="6[67+7]" />

<Notes />

- Key reason that the postfix keyword was chosen
- Nothing special here, just a combination of existing ideas

---

# Forgetting to await

import forgetAwait from "rust:async/forget-await";

<Transclude src={forgetAwait} focusOn="1+7" />

<Continue />

import forgetAwaitStderr from "stderr:async/forget-await";

<Transclude lang="compiler-error" src={forgetAwaitStderr} focusOn="1+7" />

<Notes />

- TODO: are there times we don't want to `.await`?
  - returning a future?
  - spawning a task

---

# `impl Future`

import implFutureSugar from "rust:async/impl-future-sugar";

<Transclude src={implFutureSugar} focusOn="1+3" />

<Continue />

## Same as

<Transclude src={implFutureSugar} focusOn="5+3" />

---

# `impl Future`

<Transclude src={implFutureSugar} focusOn="9+7" />

<Notes />

- Futures predate async/await (2016-07-31 vs 2019-11-07)
- TODO: was there a place where lifetime annotations worked better?

---

# The `Future` trait

<FutureTrait />

<Continue />

<PollEnum />

<Continue />

Compare / contrast to:

<IteratorTrait />

<OptionEnum />

---

# Executors

- Starts the initial call to `Future::poll`
- Unlike other languages, not built in

---

<SlideLayout use={Splash} />

import executorImg from "./assets/async/executor.png";

<img className={styles.fullImage} src={executorImg} alt="The executor loop" />

<Notes />

- Executor goes through tasks until they all say pending, sleeps
- Task gets a waker, that waker is given _somewhere_ and can be used when more data available

---

# Common executors

- futures
- Tokio
- async-std
- smol

<Notes />

- async-std and smol share dependencies

---

# Language vs. standard library vs. crates

- `async` and `await` are built into the language
- `Future` is part of the standard library
- crates provide useful capabilities

<Notes />

- Just like other parts of Rust, many things pushed to crates
- There's no One True executor
- Tradeoffs and strengths
- embedded

---

# The futures crate

- additional abstractions
- common functionality
- channels
- IO abstractions
- executor
  - tasks

---

# Driving a future to completion

import driveToCompletionToml from "toml:async/drive-to-completion";
import driveToCompletion from "rust:async/drive-to-completion";

<Transclude lang="toml" src={driveToCompletionToml} focusOn="8+2" />
<Transclude src={driveToCompletion} />

<Notes />

- Not the only executor

---

# The `FutureExt` trait

<FutureExtTrait />

<Continue />

- methods like `map` or `into_stream`

---

# Futures that can fail

<TryFutureTrait />

<Continue />

<TryFutureExtTrait />

- methods like `map_ok` and `map_err`

---

# Streams

<StreamTrait />

<Notes />

- Not in the standard library (yet!)
- Analog to `Iterator`

<Continue />

<StreamExtTrait />

- methods like
  - `map`, `fold`, `enumerate`
  - `buffer`, `buffer_unordered`, `for_each_concurrent`

---

# Streams that can fail

<TryStreamTrait />

<Notes />

<Continue />

<TryStreamExtTrait />

- methods like `and_then`, `or_else`

---

# Common combinators

- `join`
- `select`
- `now_or_never`
- `buffer_unordered`

---

# Exercise

- Create three async functions
  - Take in some numbers, return some numbers
- Call these functions from `main`
- Use `futures::future::join` to run two concurrently
- Use `futures::executor::block_on` to drive futures to completion

## Bonus

- Use `select` to race two concurrently
- Create a stream from calling one of the async functions
  - Sum it up

---

# One answer

<Continue />

import exerciseCombinatorsToml from "toml:async/exercise-combinators";
import exerciseCombinators from "rust:async/exercise-combinators";

<Transclude lang="toml" src={exerciseCombinatorsToml} focusOn="8+2" />
<Transclude src={exerciseCombinators} focusOn="1+15,17,19,21" />

---

# Concurrency vs. parallelism

import concurrencyVsParallelism from "rust:async/concurrency-vs-parallelism";

<Transclude src={concurrencyVsParallelism} focusOn="1+13" />

<Continue />

import concurrencyVsParallelismStdout from "stdout:async/concurrency-vs-parallelism";

<Transclude lang="compiler-output" src={concurrencyVsParallelismStdout} />

---

# Concurrency vs. parallelism

import concurrencyVsParallelismRev1 from "rust:async/concurrency-vs-parallelism?rev=1";

<Transclude
  src={concurrencyVsParallelismRev1}
  focusOn="1+13"
  emphasize="7[12+18],8[12+18]"
/>

<Continue />

import concurrencyVsParallelismRev1Stdout from "stdout:async/concurrency-vs-parallelism?rev=1";

<Transclude lang="compiler-output" src={concurrencyVsParallelismRev1Stdout} />

---

# Tasks

- Similar to threads
- Lightweight
- Millions of tasks should be fine

<Notes />

- Run into OS limitations (file handles, memory) first
- TODO: Some kind of picture here (extension of previous one?)

---

# Parallelism requires executor support

- Futures
  - ❌ `futures::executor::block_on`
  - ✅ `futures::executor::ThreadPool`
- Tokio
  - ❌ `tokio::runtime::Builder::new_current_thread()`
  - ✅ `tokio::runtime::Builder::new_multi_thread()`

<Notes />

- Both use feature flags, differences in default enabled
  - Futures: `executor`, `thread-pool`
  - Tokio: `rt`, `rt-multi-thread`
- Spawning a task doesn't guarantee parallelism
  - Just like you can spawn threads on a single-core system

---

# Calling asynchronous code from synchronous

- Once
- Sequentially
- Concurrently

---

# Once (Tokio)

import asyncFromSyncOnceTokio from "rust:async/async-from-sync-once-tokio";

<Transclude src={asyncFromSyncOnceTokio} />

<Notes />

- The procedural macro `tokio::main` wraps the body of the function
  with code that starts an executor, submits the body, blocking on it.
- Most common way of starting the executor.

---

# Sequentially (Tokio)

import asyncFromSyncSequentialTokio from "rust:async/async-from-sync-sequential-tokio";

<Transclude src={asyncFromSyncSequentialTokio} />

<Notes />

- Create one runtime and reuse it

---

# Concurrently (Tokio)

import asyncFromSyncConcurrentTokio from "rust:async/async-from-sync-concurrent-tokio";

<Transclude src={asyncFromSyncConcurrentTokio} />

<Notes />

- Create a runtime on a separate thread and send pieces of work to it.
- Can also involve another channel back for intermediate results.

---

# Once (async-std)

import asyncFromSyncOnceAsyncStd from "rust:async/async-from-sync-once-async-std";

<Transclude src={asyncFromSyncOnceAsyncStd} />

<Notes />

- The procedural macro `async_std::main` wraps the body of the
  function with code that submits the body to the executor, blocking
  on it.

---

# Sequentially (async-std)

import asyncFromSyncSequentialAsyncStd from "rust:async/async-from-sync-sequential-async-std";

<Transclude src={asyncFromSyncSequentialAsyncStd} />

<Notes />

- async-std has a global executor that is lazily-initialized; no need
  to do it ourselves

---

# Concurrently (async-std)

import asyncFromSyncConcurrentAsyncStd from "rust:async/async-from-sync-concurrent-async-std";

<Transclude src={asyncFromSyncConcurrentAsyncStd} />

---

# Asynchronous tests

import asyncTests from "rust:async/async-tests";
import asyncTestsRev1 from "rust:async/async-tests?rev=1";

<Transclude src={asyncTests} focusOn="1+5" />
<Transclude src={asyncTestsRev1} focusOn="1+5" />

<Notes />

- Rust's test framework is multithreaded
- Tokio's test annotation creates a "smaller" executor

---

# Calling synchronous code from asynchronous

<Quote caption="Tokio documentation" href="https://docs.rs/tokio/latest/tokio/#cpu-bound-tasks-and-blocking-code">

[C]ode that spends a long time without reaching an `.await` will prevent other tasks from running

</Quote>

---

# Calling synchronous code from asynchronous

- IO-bound / blocking
  - Reading from a file
  - Reading from a socket that isn't in non-blocking mode
  - Using `Mutex` / `RwLock` / channels
  - Sleeping
- CPU-bound

<Notes />

- As a rough guideline, aim for max 1 ms between await points.
- Don't need to worry if you are performing small calculations.

---

# Blocking

import blocking from "rust:async/blocking";

<Transclude src={blocking} />

<Continue />

import blockingStdout from "stdout:async/blocking";

<Transclude lang="compiler-output" src={blockingStdout} />

<Notes />

- Never perform anything that would block inside an asynchronous task

---

# Blocking correctly (Tokio)

import blockingRev1 from "rust:async/blocking?rev=1";
import blockingRev1Stdout from "stdout:async/blocking?rev=1";

<Transclude
  src={blockingRev1}
  emphasize="8[12+20],8[33+2],12[12+20],12[33+2],18[58+21],19[58+21]"
/>
<Transclude lang="compiler-output" src={blockingRev1Stdout} />

<Notes />

- Creates threads that can just sit there blocked by the OS
- More expensive than a task

---

# Blocking correctly (async-std)

import blockingRev2 from "rust:async/blocking?rev=2";
import blockingRev2Stdout from "stdout:async/blocking?rev=2";

<Transclude
  src={blockingRev2}
  emphasize="8[12+20],8[33+2],12[12+20],12[33+2]"
/>
<Transclude lang="compiler-output" src={blockingRev2Stdout} />

---

# CPU-bound

- Async code works best when you are waiting most of the time
- Blocking work can be handed off to lots of threads
  - That wait most of the time
- Don't create lots of threads that are all active
  - Causes CPU contention

---

# CPU-bound (Tokio)

import cpuBound from "rust:async/cpu-bound";

<Transclude src={cpuBound} focusOn="1+18" />

---

# CPU-bound (Tokio)

<Transclude src={cpuBound} focusOn="20+16" />

---

# CPU-bound (Tokio)

import cpuBoundStdout from "stdout:async/cpu-bound";

<Transclude lang="compiler-output" src={cpuBoundStdout} />

---

# Common gotchas

- Files
- Holding a blocking lock across an await point

<Notes />

- OSes generally don't have a non-blocking file API, but that's changing
- The lock guard prevents spawning the task

---

# Lifetimes

import lifetimes from "rust:async/lifetimes";

<Transclude src={lifetimes} />

<Notes />

- Works pretty much the same, most of the time
- https://github.com/rust-lang/rust/issues/42940

---

# Sharing data

import sharingData from "rust:async/sharing-data";

<Transclude src={sharingData} />

---

# Sharing data

import sharingDataStderr from "stderr:async/sharing-data";

<Transclude lang="compiler-error" src={sharingDataStderr} focusOn="1+19" />

---

# Sharing data

import sharingDataRev1 from "rust:async/sharing-data?rev=1";

<Transclude src={sharingDataRev1} emphasize="11[22+4]" />

---

# Sharing mutable data

import sharingMutableData from "rust:async/sharing-mutable-data";

<Transclude src={sharingMutableData} />

---

# Sharing mutable data

import sharingMutableDataStderr from "stderr:async/sharing-mutable-data";

<Transclude
  lang="compiler-error"
  src={sharingMutableDataStderr}
  focusOn="1+18"
/>

---

# Sharing mutable data

<Transclude
  lang="compiler-error"
  src={sharingMutableDataStderr}
  focusOn="20+15"
/>

---

# Sharing mutable data

import sharingMutableDataRev1 from "rust:async/sharing-mutable-data?rev=1";

<Transclude
  src={sharingMutableDataRev1}
  emphasize="4,8[17+20],8[47+2],12,14[22+32]"
/>

---

# Using channels

<ChannelQuote />

---

# Using channels

import channels from "rust:async/channels";

<Transclude src={channels} focusOn="6+26" />

<Notes />

- Move mutation into a separate task

- Can be prettied up into a lightweight "actor" concept
  - Wrap the `tx` in a little struct with methods that send
  - Introduce an enum for the different methods desired
  - Match on the enum in the spawned task
  - Use a oneshot channel to handle response
  - Maybe make it a `spawn_blocking` as needed

---

# Asynchronous IO

## `futures::io::AsyncRead`

<FuturesIoAsyncReadPollRead emphasize="4[9+9],5[10+13]" />

## `tokio::io::AsyncRead`

<TokioAsyncReadPollRead emphasize="4[9+16],5[10+14]" />

<Notes />

- Very confusing to have similar traits with same names but small differences!
- `futures` is also `async_std::io::Read`

---

# `AsyncReadExt`

## `futures::io::AsyncReadExt`

<FuturesAsyncReadExtRead />

## `tokio::io::AsyncReadExt`

<TokioAsyncReadExtRead />

<Notes />

- Still confusing, but at least they are the same here.

---

import exerciseEchoServerToml from "toml:async/exercise-echo-server";
import exerciseEchoServer from "rust:async/exercise-echo-server";

# Exercise

- Create a TCP echo server

## Hints

- **Cargo.toml**
  <Transclude
    lang="toml"
    src={exerciseEchoServerToml}
    focusOn="9"
    copyAllCode={false}
  />
- **main.rs**
  <Transclude src={exerciseEchoServer} focusOn="5" copyAllCode={false} />
- **client**
  ```
  nc 127.0.0.1 9876
  ```

## Bonus

- Support multiple clients

---

# One answer

<Continue />

<Transclude src={exerciseEchoServer} />

---

# Ecosystem libraries

- [aws-sdk-rust][] — AWS SDK
- [hyper][] — HTTP/1 and HTTP/2 implementation
- [reqwest][] — Ergonomic, batteries-included HTTP client
- [tonic][] — gRPC over HTTP/2

[aws-sdk-rust]: https://github.com/awslabs/aws-sdk-rust
[hyper]: https://crates.io/crates/hyper
[reqwest]: https://crates.io/crates/reqwest
[tonic]: https://crates.io/crates/tonic

---

# Ecosystem (web server) frameworks

- [axum][] — focuses on ergonomics and modularity
- [warp][] — super-easy and composable for warp speeds
- [actix-web][] — powerful, pragmatic, and extremely fast
- [rocket][] — a focus ease-of-use, expressibility, and speed

[axum]: https://crates.io/crates/axum
[warp]: https://crates.io/crates/warp
[actix-web]: https://crates.io/crates/actix-web
[rocket]: https://crates.io/crates/rocket

---

# Annoyances and gotchas

- Use of `async fn` in public traits is discouraged
  - [async-trait][]
  - [trait-variant][]

[async-trait]: https://crates.io/crates/async-trait
[trait-variant]: https://crates.io/crates/trait-variant

---

# Annoyances and gotchas

- Tokio vs async-std
  - not all concepts are abstracted
  - some are overlapping
  - tasks (`spawn`)
  - io (`AsyncRead`)
  - `Stream` isn't stable
- Tokio needs feature flags

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
