import { createSlice } from "@reduxjs/toolkit";

export interface KeyboardState {
  meta: boolean;
}

const initialState: KeyboardState = {
  meta: false,
};

export const keyboardSlice = createSlice({
  name: "keyboard",
  initialState,
  reducers: {
    toggleMeta: (state) => {
      state.meta = !state.meta;
    },
  },
});

export const { toggleMeta } = keyboardSlice.actions;

export default keyboardSlice.reducer;
