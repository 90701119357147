import { useAppSelector } from "./app/hooks";
import Presenter from "./Presenter";
import Display from "./Display";
import Print from "./Print";

import * as styles from "./ModeSelect.module.css";

const DISPATCH = {
  display: Display,
  presenter: Presenter,
  print: Print,
};

const ModeSelect: React.FC = () => {
  const mode = useAppSelector((state) => state.deck.mode);
  const Child = DISPATCH[mode];

  return (
    <div className={styles.container}>
      <Child />
    </div>
  );
};

export default ModeSelect;
