import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";

export const title = "Embedded development";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# Embedded development

---

# Cross-compiling

All Rust compilers are cross-compilers

```compiler-output
% cargo build --target=thumbv7em-none-eabihf
```

---

# But the standard library isn't always available

```compiler-error
error[E0463]: can't find crate for `std`
  |
  = note: the `thumbv7em-none-eabihf` target may not be installed
```

---

# The standard library is a facade

- Platform interaction
- Re-exports APIs from lower-level crates
  - `libcore`
  - `liballoc`

---

# `libcore`

- Dependency-free
- Platform agnostic
- Fundamental traits and types
- Methods for primitive types

---

# `liballoc`

- Heap-allocated values
  - Smart pointers
  - Collections

---

# Important websites

- The Discovery Book

  - https://rust-embedded.github.io/discovery/
  - https://github.com/rust-embedded/discovery/

- The Embedded Book
  - https://rust-embedded.github.io/book/

---

# Install new tools

## libcore

```compiler-output
% rustup target add thumbv7em-none-eabihf
```

<Notes />

- There's also a tool called Xargo that helps compile your own libcore/liballoc/libstd

---

# Install new tools

## cargo-embed

```compiler-output
% cargo install cargo-embed
```

<Notes />

- Flashes the board
- Provides a local GDB server

---

# Install new tools

## minicom

```compiler-output
# macOS
% brew install minicom
```

<Notes />

- Connects to serial device

---

# Install new tools

## GDB

```compiler-output
# macOS
% brew tap ArmMbed/homebrew-formulae
% brew install arm-none-eabi-gcc
```

<Notes />

- Connects to cargo-embed to allow breakpoints and stepping

---

# Differences for embedded code

## src/05-led-roulette/src/main.rs

import embedded from "rust:extracted/embedded.rs";

<Transclude src={embedded} />

---

# Differences for embedded code

## src/05-led-roulette/src/main.rs

<Transclude src={embedded} emphasize="1" />

<Notes />

- `deny` - changes warning into an error
- Not 100% sure why they have this, but I think it's mostly to prove a point

---

# Differences for embedded code

## src/05-led-roulette/src/main.rs

<Transclude src={embedded} emphasize="2" />

<Notes />

- `no_main` - Disables the standard Rust entrypoint

---

# Differences for embedded code

## src/05-led-roulette/src/main.rs

<Transclude src={embedded} emphasize="3" />

<Notes />

- `no_std` - prevents linking to the standard library

---

# Differences for embedded code

## src/05-led-roulette/src/main.rs

<Transclude src={embedded} emphasize="5,9" />

<Notes />

- `entry` is a procedural macro that rewrites the `main` function to be a valid entrypoint

---

# Differences for embedded code

## src/05-led-roulette/src/main.rs

<Transclude src={embedded} emphasize="10[10+4],15,16" />

<Notes />

- `!` is the never type - function must not return

---

# First program

## Build and load the code

```compiler-output
% cargo embed --features v2 --target thumbv7em-none-eabihf
```

```compiler-output
% gdb target/thumbv7em-none-eabihf/debug/led-roulette

(gdb) target remote :1337
...
(gdb) break main
...
(gdb) c
Continuing.

Breakpoint 1, led_roulette::__cortex_m_rt_main_trampoline () at src/05-led-roulette/src/main.rs:9
9   #[entry]
(gdb) s
led_roulette::__cortex_m_rt_main () at src/05-led-roulette/src/main.rs:12
12      let x = 42;
```

<Notes />

- In two windows

---

# Doing something useful

- Goal: blink the lights in some interesting way

---

# How do we access the lights?

```compiler-output
cargo doc --target thumbv7em-none-eabihf --features v2 --open
```

---

# Live coding

<Notes />

- Talk about?
  - volatile reads / writes
  - types for safety
  - .cargo/config file
  - GDB init file

---

# RTOS

- https://www.tockos.org/
- https://rtic.rs/

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
