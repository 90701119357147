import * as OverviewMdx from "./overview.mdx";
import * as IntroMdx from "./intro.mdx";
import * as OwnershipMdx from "./ownership.mdx";
import * as OptionAndResultMdx from "./option-and-result.mdx";
import * as ErrorHandlingMdx from "./error-handling.mdx";
import * as IteratorsMdx from "./iterators.mdx";
import * as TypesMdx from "./types.mdx";
import * as ConcurrencyMdx from "./concurrency.mdx";
import * as AsyncMdx from "./async.mdx";
import * as FromDynamicLanguagesMdx from "./from-dynamic-languages.mdx";
import * as ReliableCodeUsingTypesMdx from "./reliable-code-using-types.mdx";
import * as TestingMdx from "./testing.mdx";
import * as StringsVsBytesMdx from "./strings-vs-bytes.mdx";
import * as MacrosMdx from "./macros.mdx";
import * as FfiMdx from "./ffi.mdx";
import * as EmbeddedMdx from "./embedded.mdx";

export const DECKS = {
  overview: OverviewMdx,
  intro: IntroMdx,
  ownership: OwnershipMdx,
  "option-and-result": OptionAndResultMdx,
  "error-handling": ErrorHandlingMdx,
  iterators: IteratorsMdx,
  types: TypesMdx,
  concurrency: ConcurrencyMdx,
  async: AsyncMdx,
  "from-dynamic-languages": FromDynamicLanguagesMdx,
  "reliable-code-using-types": ReliableCodeUsingTypesMdx,
  testing: TestingMdx,
  "strings-vs-bytes": StringsVsBytesMdx,
  macros: MacrosMdx,
  ffi: FfiMdx,
  embedded: EmbeddedMdx,
};

export type DeckId = keyof typeof DECKS;

const DECK_IDS = Object.keys(DECKS);
const DEFAULT_DECK: DeckId = "overview";

const isValidDeckType = (s: string): s is DeckId =>
  DECK_IDS.includes(s as DeckId);

export const validateDeckType = (s: string | null): DeckId =>
  s && isValidDeckType(s) ? s : DEFAULT_DECK;
