import { useEffect } from "react";

import { useAppSelector } from "./app/hooks";

const HtmlStyler = () => {
  const fontSize = useAppSelector((s) => s.window.fontSize);

  useEffect(() => {
    window.document.documentElement.style.fontSize = `${fontSize}%`;
  }, [fontSize]);

  return null;
};

export default HtmlStyler;
