import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";

import {
  IteratorByRef,
  IteratorCollect,
  IteratorCycle,
  IteratorFilter,
  IteratorMap,
  IteratorRev,
  IteratorRposition,
  IteratorTrait,
  IntoIteratorTrait,
  CharsIterator,
} from "./Excerpts";

export const title = "Iterators";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# Iterators

---

# The `Iterator` trait

<IteratorTrait />

---

# The `Iterator` trait

<IteratorTrait emphasize="2" />

<Notes />

- An associated type is a type that the implementor of the trait picks.

---

# The `Iterator` trait

<IteratorTrait emphasize="4" />

<Notes />

- `next` returns the next value or `None`
- `Option` prevents the need for a `has_next`
- `self` is mutable to update the state

---

# Iterators are pervasive

- "memorize" what's available
- [`Iterator` docs](https://doc.rust-lang.org/std/iter/trait.Iterator.html)

---

# Getting an iterator

- Some types are inherently iterators
  - Ranges (`0..` / `10..=20` / etc.)
- `std::iter` contains some free functions
- Some types provide methods to return an iterator
  - specialty iterators (`str::chars`, `HashMap::keys`)
  - `iter()` / `iter_mut()` / `into_iter()`
- The `IntoIterator` trait
  - `&foo` / `&mut foo` / `foo`

---

import implementsIteratorDocs from "./assets/iterators/implements-iterator-docs.png";

# Identifying types that are iterators

<img src={implementsIteratorDocs} alt="list of traits a type implements" />

Left-hand side of the documentation lists all the traits the type implements

---

import importantTraitIndicator from "./assets/iterators/important-trait-indicator.png";
import importantTraitList from "./assets/iterators/important-trait-list.png";

# Identifying methods that return iterators

<img
  src={importantTraitIndicator}
  alt="indication that return type has useful traits"
/>

<Continue />

<br />

<img src={importantTraitList} alt="list of useful traits of return type" />

---

# `IntoIterator`

<IntoIteratorTrait />

<Notes />

- Nastier type signature, but conceptually simpler - any type that can be converted into an iterator

---

# `for` loops use `IntoIterator`

import forLoopSugar from "rust:iterators/for-loop-sugar";

<Transclude src={forLoopSugar} focusOn="2+3" />

<Continue />

<Transclude src={forLoopSugar} focusOn="6+4" />

<Notes />

- This can be confusing, as a `Vec` is not an iterator itself, but can be transformed into one
- Can't call iterator methods on the collection directly though

---

# Closures

- Allow providing small bits of code
- Sometimes called anonymous functions or lambdas

---

# Closures in Rust

- `Fn`
  - Can be called multiple times
  - Can not mutate the closure's environment
- `FnMut`
  - Can be called multiple times
  - Can mutate the closure's environment
- `FnOnce`
  - Can be called only once
  - Can take ownership of the closure's environment

---

# Closures in Rust

import closure from "rust:iterators/closure";

<Transclude src={closure} />

---

# Closures in Rust

<Transclude src={closure} emphasize="1[20+1],1[30+1]" />

<Notes />

- Introduce a generic type and use it as an argument

---

# Closures in Rust

<Transclude src={closure} emphasize="2,3" />

<Notes />

- Constrain the generic type to implement a specific closure trait

---

# Closures in Rust

<Transclude src={closure} emphasize="11[21+21]" />

<Notes />

- Closure arguments are in pipes

---

# `Iterator::map`

<IteratorMap />

Closure given an item, returns a new item.

<Notes />

- Maybe _the_ most used iterator adapter

<Continue />

### Example

import iteratorMap from "rust:iterators/iterator-map";

<Transclude src={iteratorMap} focusOn="2+3" emphasize="1[16+15]" />

---

# `Iterator::filter`

<IteratorFilter />

Closure given a reference to an item, returns `false` if this item should be discarded.

<Notes />

- I always find myself confused by the polarity of this, so don't feel bad
- Any thoughts on why it's a reference?

<Continue />

### Example

import iteratorFilter from "rust:iterators/iterator-filter";

<Transclude src={iteratorFilter} focusOn="2+3" emphasize="1[16+19]" />

<Transclude src={iteratorFilter} focusOn="6+3" emphasize="1[16+19]" />

<Transclude src={iteratorFilter} focusOn="10+3" emphasize="1[16+19]" />

<Notes />

- Since you have a reference, need to dereference to compare it

---

# `Iterator::collect`

<IteratorCollect />

Builds a collection of values from the iterator.

<Notes />

- Note that the return type is a generic

<Continue />

### Example

import iteratorCollect from "rust:iterators/iterator-collect";

<Transclude src={iteratorCollect} focusOn="4" />

<Transclude src={iteratorCollect} focusOn="5" />

<Transclude src={iteratorCollect} focusOn="6" />

---

# Exercise

- Take the values from 0 (inclusive) to 10 (exclusive)
- Square them
- Remove the results that are larger than 50
- Store them into a `Vec` and print it

---

# One answer

<Continue />

import exampleCombinators from "rust:iterators/example-combinators";

<Transclude src={exampleCombinators} />

---

# Iterators are efficient

- They are lazy
  - Only enough work done to return the next element
- Closures are inlined
  - Optimizer can see the entire computation
- Can hint how many elements remain
  - Used to avoid reallocating collections

<Notes />

import optimizeToConstant from "rust:iterators/optimize-to-constant";

- Lazy means that if it's never used, no work is done other than to construct it
- Optimized to `140`

  <Transclude src={optimizeToConstant} focusOn="1+3" />

---

# Iterators vs indexing

### Avoid

import iteratorVsIndex from "rust:iterators/iterator-vs-index";

<Transclude src={iteratorVsIndex} focusOn="2+5" />

### Prefer

<Transclude src={iteratorVsIndex} focusOn="10+4" />

<Notes />

- More idiomatic and concise
- Use `enumerate` if you also need an index
- Not _always_ better, as the borrowing is different

---

# Returning iterators

import returningIterators from "rust:iterators/returning-iterators";

<Transclude src={returningIterators} />

---

# Returning iterators

import returningIteratorsRev1 from "rust:iterators/returning-iterators?rev=1";

<Transclude src={returningIteratorsRev1} focusOn="1+3" />

<Notes />

- We could convert the `Vec` into an iterator and return it

<Continue />

import returningIteratorsRev1Stderr from "stderr:iterators/returning-iterators?rev=1";

<Transclude
  lang="compiler-error"
  src={returningIteratorsRev1Stderr}
  focusOn="1+17"
  emphasize="14,15,16,17"
/>

---

# Returning iterators

import returningIteratorsRev2 from "rust:iterators/returning-iterators?rev=2";

<Transclude src={returningIteratorsRev2} />

<Notes />

- But now it's part of our API, can't change it

---

# Returning iterators

import returningIteratorsRev3 from "rust:iterators/returning-iterators?rev=3";

<Transclude src={returningIteratorsRev3} focusOn="5+3" />

<Continue />

import returningIteratorsRev3Stderr from "stderr:iterators/returning-iterators?rev=3";

<Transclude
  lang="compiler-error"
  src={returningIteratorsRev3Stderr}
  focusOn="1+18"
/>

<Notes />

- You can't actually name the type of a closure

---

# Returning iterators using `impl Trait`

import returningIteratorsRev4 from "rust:iterators/returning-iterators?rev=4";

<Transclude src={returningIteratorsRev4} emphasize="1[19+34],5[25+34]" />

<Notes />

- The compiler knows the concrete type that the function will return
- The calling code can not rely on anything beyond the signature

---

# Conditionally returning iterators

import conditionallyReturningIterators from "rust:iterators/conditionally-returning-iterators";

<Transclude src={conditionallyReturningIterators} />

---

# Conditionally returning iterators

import conditionallyReturningIteratorsStderr from "stderr:iterators/conditionally-returning-iterators";

<Transclude
  lang="compiler-error"
  src={conditionallyReturningIteratorsStderr}
  focusOn="1+28"
/>

---

# Conditionally returning iterators

<Transclude
  lang="compiler-error"
  src={conditionallyReturningIteratorsStderr}
  focusOn="29+10"
/>

---

# Conditionally returning iterators using trait objects

import conditionallyReturningIteratorsRev1 from "rust:iterators/conditionally-returning-iterators?rev=1";

<Transclude
  src={conditionallyReturningIteratorsRev1}
  emphasize="5[35+38],7[8+9],7[59+1],9[8+9],9[59+1]"
/>

---

# Implementing an iterator

### Trait definition

<IteratorTrait />

<Continue />

### Trait implementation

import implementingIterator from "rust:iterators/implementing-iterator";

<Transclude src={implementingIterator} focusOn="1+7" />

---

# Implementing an iterator

### Trait definition

<IteratorTrait />

### Trait implementation

<Transclude src={implementingIterator} focusOn="1+7" emphasize="1" />

---

# Implementing an iterator

### Trait definition

<IteratorTrait />

### Trait implementation

<Transclude src={implementingIterator} focusOn="1+7" emphasize="2[16+20]" />

---

# Implementing an iterator

### Trait definition

<IteratorTrait />

### Trait implementation

<Transclude src={implementingIterator} focusOn="1+7" emphasize="5[8+4]" />

---

# Iterator implementation examples

### Returning an integer forever

<Transclude src={implementingIterator} focusOn="12+9" />

<Notes />

- Already exists as `iter::repeat`

<Continue />

<Transclude src={implementingIterator} focusOn="36+3" />

<Notes />

- Note that we call `take` to limit the infinite iterator

---

# Iterator implementation examples

### Returning an incrementing value

<Transclude src={implementingIterator} focusOn="22+10" />

<Notes />

- Already exists as a range
- Making use of mutable `self` to update our state

<Continue />

<Transclude src={implementingIterator} focusOn="40+3" />

---

import exercisePowersOfTwo from "rust:iterators/exercise-powers-of-two";

# Exercise

- Create an iterator that returns the powers of two
- Code like this should pass:

  <Transclude src={exercisePowersOfTwo} focusOn="20+4" copyAllCode={false} />

## Bonus

- Create a function called `powers_of_two`
  - Use only existing iterators and adapters from the standard library

---

# One answer

<Continue />

<Transclude src={exercisePowersOfTwo} />

---

# Iterators are standalone types

- They can have public fields or methods
- They can implement other traits besides `Iterator`

### `std::str::Chars`

<CharsIterator />

---

# Ownership of iterators is often transferred

import ownershipTransfer from "rust:iterators/ownership-transfer";

<Transclude src={ownershipTransfer} focusOn="1+10" />

<Notes />

- This isn't necessarily the best way of doing this!

<Continue />

import ownershipTransferStderr from "stderr:iterators/ownership-transfer";

<Transclude
  lang="compiler-error"
  src={ownershipTransferStderr}
  focusOn="1+12"
/>

---

# Preventing ownership transfers

<IteratorByRef />

<Continue />

import ownershipTransferRev1 from "rust:iterators/ownership-transfer?rev=1";

<Transclude
  src={ownershipTransferRev1}
  focusOn="1+10"
  emphasize="3[8+3],4[20+9]"
/>

<Continue />

import ownershipTransferRev2 from "rust:iterators/ownership-transfer?rev=2";

<Transclude
  src={ownershipTransferRev2}
  focusOn="1+10"
  emphasize="3[8+3],4[13+4]"
/>

---

# Some methods have stricter requirements

<IteratorCycle />
<IteratorRev />
<IteratorRposition />

---

# Some methods have stricter requirements

<IteratorCycle emphasize="4[22+5]" />
<IteratorRev emphasize="4[22+19]" />
<IteratorRposition emphasize="5[22+39]" />

---

# Itertools

- Extra iterator adaptors and methods
- Free functions to start from

import usingItertoolsToml from "toml:iterators/using-itertools";
import usingItertools from "rust:iterators/using-itertools";

<Transclude lang="toml" src={usingItertoolsToml} focusOn="8+2" />

<Transclude src={usingItertools} />

---

# Rayon

- Provides parallel iterators

import usingRayonToml from "toml:iterators/using-rayon";
import usingRayon from "rust:iterators/using-rayon";

<Transclude lang="toml" src={usingRayonToml} focusOn="8+2" />
<Transclude src={usingRayon} />

<Notes />

- Also does some other things; just known for parallel iterators

---

# Common issues

- Multiple references
- Over- and under-eager collection
- Iterators returning references to themselves

---

# Multiple references

import multipleReferences from "rust:iterators/multiple-references";

<Transclude src={multipleReferences} />

<Continue />

import multipleReferencesStderr from "stderr:iterators/multiple-references";

<Transclude
  lang="compiler-error"
  src={multipleReferencesStderr}
  focusOn="1+5"
/>

---

# Multiple references

<Transclude src={multipleReferences} emphasize="3[10+7]" />

- `iter` returns an iterator that implements `Iterator<Item = &T>`
- `T` is `i32`

---

# Multiple references

<Transclude src={multipleReferences} emphasize="3[17+19]" />

- `filter` accepts a closure like `Fn(&Self::Item) -> bool`
- `Self::Item` is `&i32`

---

import multipleReferencesRev1 from "rust:iterators/multiple-references?rev=1";

# Solutions for multiple references

- Do nothing

  <Transclude src={multipleReferencesRev1} focusOn="4" />

<Notes />

- Not usually needed when calling a method because methods automatically dereference

<Continue />

- Dereference once

  <Transclude src={multipleReferencesRev1} focusOn="6" emphasize="1[22+1]" />
  <Transclude src={multipleReferencesRev1} focusOn="7" emphasize="1[26+1]" />

<Notes />

- Both are valid, but the former is more idiomatic

<Continue />

- Dereference multiple times

  <Transclude src={multipleReferencesRev1} focusOn="9" emphasize="1[22+2]" />

<Notes />

- Generally only dereference "all the way" for types that implement `Copy`

---

# Over-eager collection

import eagerCollection from "rust:iterators/eager-collection";

<Transclude src={eagerCollection} />

---

# Over-eager collection

<Transclude src={eagerCollection} emphasize="2[15+8],2[48+10]" />

<Notes />

- Unneeded memory allocation; loss of performance for no reason

---

# Over-eager collection

import eagerCollectionRev1 from "rust:iterators/eager-collection?rev=1";

<Transclude src={eagerCollectionRev1} />

---

# Under-eager collection

import nonEagerCollection from "rust:iterators/non-eager-collection";

<Transclude src={nonEagerCollection} />

<Notes />

- `flat_map`'s closure takes ownership of the iterator item, but `chars` returns references to it

<Continue />

import nonEagerCollectionStderr from "stderr:iterators/non-eager-collection";

<Transclude
  lang="compiler-error"
  src={nonEagerCollectionStderr}
  focusOn="1+10"
/>

---

# Under-eager collection

import nonEagerCollectionRev1 from "rust:iterators/non-eager-collection?rev=1";

<Transclude src={nonEagerCollectionRev1} emphasize="5[39+20]" />

<Notes />

- Can eagerly collect the inner iterator so no references outlive the closure

---

# Iterators that reference themselves

import itemRefersToIterator from "rust:iterators/item-refers-to-iterator";

<Transclude src={itemRefersToIterator} focusOn="1+12" />

<Continue />

import itemRefersToIteratorStderr from "stderr:iterators/item-refers-to-iterator";

<Transclude
  lang="compiler-error"
  src={itemRefersToIteratorStderr}
  focusOn="1+5"
/>

---

# Iterators that reference themselves

import itemRefersToIteratorRev1 from "rust:iterators/item-refers-to-iterator?rev=1";

<Transclude src={itemRefersToIteratorRev1} focusOn="6+7" emphasize="2[13+4]" />

<Transclude src={itemRefersToIteratorRev1} focusOn="14+7" />

<Notes />

- This is a completely different trait, not `Iterator`
- Uses GATs to have a generic lifetime for the associated type
- Calling code must guarantee that the item is only used until call to `next`

---

# In the future: Coroutines

import coroutines from "rust:iterators/coroutines";

<Transclude src={coroutines} focusOn="3+7" />

<Notes />

- Not valid in stable Rust
- Coroutines are the implementation underlying Rust's async/await

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
