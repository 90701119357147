.-resizingSlideContainer {
  max-width: 100%;
  max-height: 100%;
}

.-meta {
  background-color: ivory;
}

.-label {
  padding: 0.25em;
  margin: 0;
}

.container {
  composes: -expand from "./util.module.css";
  display: grid;
  grid-template:
    "curr curr curr curr" minmax(25%, 60%)
    "lbll lbll lblr lblr" minmax(min-content, min-content)
    "note note next next" minmax(25%, 1fr)
    "note note time time" minmax(min-content, min-content);
  grid-template-columns: repeat(4, minmax(0, 25%));
}

.slideCurrent {
  composes: -center from "./util.module.css";
  composes: -resizingSlideContainer;
  grid-area: curr;
}

.labelLeft {
  composes: -meta -label;
  grid-area: lbll;
}

.labelRight {
  composes: -meta -label;
  grid-area: lblr;
  text-align: right;
}

.notes {
  composes: -meta;
  grid-area: note;
}

.slideNext {
  composes: -center from "./util.module.css";
  composes: -meta -resizingSlideContainer;
  grid-area: next;
}

.timer {
  composes: -meta;
  padding: 0.25em;
  grid-area: time;
  text-align: center;
}
