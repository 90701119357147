import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DeckId } from "../../decks";

// Duplicated to avoid a circular reference
const DEFAULT_DECK: DeckId = "overview";

export interface RouteState {
  deck: DeckId;
}

const initialState: RouteState = {
  deck: DEFAULT_DECK,
};

export const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    setDeck: (state, action: PayloadAction<DeckId>) => {
      state.deck = action.payload;
    },
  },
});

export const { setDeck } = routeSlice.actions;

export default routeSlice.reducer;
