import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";

export const title = "Using types to write reliable code";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# Using types to write<br />reliable code

---

# The point of types

- Label what you have
- Constrain what you can do with it
- Offload bookkeeping to the compiler
- Verify more at compile time instead of run time

<Notes />

- Doesn't usually remove all runtime cost

---

import typeTheory from "rust:reliable-code-using-types/type-theory";

# Fancy type theory terms

### `struct`

- "Product type"
- Each field multiplies the number of possible states

  <Transclude src={typeTheory} focusOn="1+4" />

<Continue />

### `enum`

- "Sum type"
- Each variant increments the number of possible states

  <Transclude src={typeTheory} focusOn="6+4" />

<Notes />

- Use these tools to get as narrow an outline around your domain as possible

---

import newtypes from "rust:reliable-code-using-types/newtypes";

# Everything looks the same

<Transclude src={newtypes} focusOn="1+3" />

<Notes />

- "primitive obsession"

<Continue />

<Transclude src={newtypes} focusOn="9" />

<Notes />

- Oops, just lost a bunch of money!

---

# Newtypes

- Give a new name to existing types
- Do not forward any abilities of the wrapped type
- Can be used to denote restrictions or constraints
- Do not take any more space than the underlying type
- Affected by visibility

<Notes />

- Rust has a nominal type system, not structural

---

import newtypesRev1 from "rust:reliable-code-using-types/newtypes?rev=1";

# Newtypes

<Transclude src={newtypesRev1} focusOn="1,3+5" />
<Transclude src={newtypesRev1} focusOn="13+1" />

---

# Newtypes

<Transclude src={newtypesRev1} focusOn="1,3+5" emphasize="1,2" />
<Transclude src={newtypesRev1} focusOn="13+1" />

---

# Newtypes

<Transclude
  src={newtypesRev1}
  focusOn="1,3+5"
  emphasize="4[24+9],4[39+9],4[58+6]"
/>
<Transclude src={newtypesRev1} focusOn="13+1" />

---

# Newtypes

<Transclude src={newtypesRev1} focusOn="1,3+5" />
<Transclude src={newtypesRev1} focusOn="13+1" emphasize="1[27+7],1[37+1]" />

---

# Newtypes

<Transclude src={newtypesRev1} focusOn="1,3+5" />
<Transclude src={newtypesRev1} focusOn="13+1" />

---

# Newtypes

import newtypesRev1Stderr from "stderr:reliable-code-using-types/newtypes?rev=1";

<Transclude lang="compiler-error" src={newtypesRev1Stderr} focusOn="1+18" />

<Notes />

- What future problem awaits after we fix the argument order?

{/* TODO: Should we show destructuring (in param, even)? */}

---

# We need to go deeper

import newtypesRev2 from "rust:reliable-code-using-types/newtypes?rev=2";

<Transclude src={newtypesRev2} focusOn="1,3+3,7+6" />
<Transclude src={newtypesRev2} focusOn="17" />

---

# We need to go deeper

<Transclude src={newtypesRev2} focusOn="1,3+3,7+6" emphasize="4,5" />
<Transclude src={newtypesRev2} focusOn="17" />

---

# We need to go deeper

<Transclude
  src={newtypesRev2}
  focusOn="1,3+3,7+6"
  emphasize="7[24+4],7[34+2]"
/>
<Transclude src={newtypesRev2} focusOn="17" />

---

# We need to go deeper

<Transclude src={newtypesRev2} focusOn="1,3+3,7+6" />
<Transclude
  src={newtypesRev2}
  focusOn="17"
  emphasize="1[15+5],1[30+1],1[33+3],1[42+1]"
/>

<Notes />

- Rust does not have named arguments at call time; this is one possible replacement
- Another is just a new struct.

---

import constrainedData from "rust:reliable-code-using-types/constrained-data";

# Newtypes for constrained data

<Transclude src={constrainedData} focusOn="1+11" />

<Notes />

- We provide a constructor that validates the constraints
- Could use a `Result` if we wanted to be informative about why
- No function that accepts an `Email` needs to perform any further checks

---

# Privacy and visibility matter

<Transclude src={constrainedData} focusOn="1+11" />

<Transclude src={constrainedData} focusOn="13+3" />

---

# Use modules to enforce visibility

import constrainedDataRev1 from "rust:reliable-code-using-types/constrained-data?rev=1";

<Transclude src={constrainedDataRev1} focusOn="1,4+12" />

<Notes />

- Remember that you are guarding against other programmers
- It's worth being paranoid in a public library, less in private library, less in application

---

# Use modules to enforce visibility

<Transclude src={constrainedDataRev1} focusOn="17+5" />

import constrainedDataRev1Stderr from "stderr:reliable-code-using-types/constrained-data?rev=1";

<Transclude
  lang="compiler-error"
  src={constrainedDataRev1Stderr}
  focusOn="1+15"
/>

---

# Downsides of newtypes

import newtypeDownsides from "rust:reliable-code-using-types/newtype-downsides";

<Transclude src={newtypeDownsides} />

<Continue />

import newtypeDownsidesStderr from "stderr:reliable-code-using-types/newtype-downsides";

<Transclude lang="compiler-error" src={newtypeDownsidesStderr} focusOn="1+18" />

<Notes />

- They are _new types_

---

# Implement all the traits and methods you need

import newtypeDownsidesRev1 from "rust:reliable-code-using-types/newtype-downsides?rev=1";

<Transclude src={newtypeDownsidesRev1} />

<Notes />

- May give you the ability to provide better names (e.g. instead of `Vec::push`))
- Add has 8 or so variants for integers
- No simple delegation syntax

---

# Be wary of implementing `Deref` and `DerefMut`

import derefAntipattern from "rust:reliable-code-using-types/deref-antipattern";

<Transclude src={derefAntipattern} />

<Notes />

- These traits are used by smart pointers like `Box`
- Allow trivially breaking the encapsulation

---

# Constrain values by inclusion

import constrainByInclusion from "rust:reliable-code-using-types/constrain-by-inclusion";

<Transclude src={constrainByInclusion} focusOn="1+8" />

<Notes />

- "stringly-typed"
- We have the panic case
- What about capitalization

---

# Only create valid values

import constrainByInclusionRev1 from "rust:reliable-code-using-types/constrain-by-inclusion?rev=1";

<Transclude src={constrainByInclusionRev1} focusOn="1+16" />

<Notes />

- Other languages and basically do this; nothing special

---

# `enum`s can store data

import enumWithData from "rust:reliable-code-using-types/enum-with-data";

<Transclude src={enumWithData} focusOn="1+20" />

---

# Doesn't work well with `&mut`

import enumWithDataRev1 from "rust:reliable-code-using-types/enum-with-data?rev=1";

<Transclude
  src={enumWithDataRev1}
  focusOn="1+20"
  emphasize="10[20+9],13[14+1]"
/>

---

# Doesn't work well with `&mut`

import enumWithDataRev1Stderr from "stderr:reliable-code-using-types/enum-with-data?rev=1";

<Transclude lang="compiler-error" src={enumWithDataRev1Stderr} focusOn="1+19" />

<Notes />

- Can clone the values (maybe `Rc` / `Arc`)
- Can make the values `Option`
- Can `take` the values if they implement `Default`

---

# Session types

import sessionTypes from "rust:reliable-code-using-types/session-types";

<Transclude src={sessionTypes} focusOn="1+5" />

---

# Session types

<Transclude src={sessionTypes} focusOn="7+25" />

---

# Session types

<Transclude src={sessionTypes} focusOn="33+3" />

<Continue />

import sessionTypesStderr from "stderr:reliable-code-using-types/session-types";

<Transclude lang="compiler-error" src={sessionTypesStderr} focusOn="1+12" />

---

import exerciseVendingMachine from "rust:reliable-code-using-types/exercise-vending-machine";

# Exercise: Vending machine state machine

- States are "idle" and "added money"
- User events are "asked for refund", "selected a drink", "added money"
- The amount of money added is in cents and is variable
- A drink costs 50 cents
- Code like this should run:

  <Transclude
    src={exerciseVendingMachine}
    focusOn="59,63+2"
    copyAllCode={false}
  />

## Hints

- Take `self` and return `Self` from your method to allow chaining
- Match on a tuple of `(state, event)`

## Bonus

- Try using session types

<Notes />

- How would making the money into an enum affect the example?

---

# One answer (1/4)

<Continue />

<Transclude src={exerciseVendingMachine} focusOn="1+12" />

---

# One answer (2/4)

<Transclude src={exerciseVendingMachine} focusOn="14+16" />

---

# One answer (3/4)

<Transclude src={exerciseVendingMachine} focusOn="31+26" />

---

# One answer (4/4)

<Transclude src={exerciseVendingMachine} focusOn="58+10" />

---

# Pattern-matching pitfalls

import patternMatchingPitfalls from "rust:reliable-code-using-types/pattern-matching-pitfalls";

<Transclude src={patternMatchingPitfalls} focusOn="1+2,4+15" />

---

# Pattern-matching pitfalls

import patternMatchingPitfallsRev1 from "rust:reliable-code-using-types/pattern-matching-pitfalls?rev=1";

<Transclude
  src={patternMatchingPitfallsRev1}
  focusOn="1+2,4+16"
  emphasize="7"
/>

<Notes />

- A catch all will _catch all_, even variants you add later
- Similar concept is possible when matching on a struct

---

# Non-exhaustive matching

import nonExhaustiveMatchingLib from "rust:reliable-code-using-types/non-exhaustive-matching/my-library/src/lib.rs";

<Transclude src={nonExhaustiveMatchingLib} />

---

# Non-exhaustive matching

import nonExhaustiveMatching from "rust:reliable-code-using-types/non-exhaustive-matching";

<Transclude src={nonExhaustiveMatching} focusOn="1+8" />

---

# Non-exhaustive matching

import nonExhaustiveMatchingStderr from "stderr:reliable-code-using-types/non-exhaustive-matching";

<Transclude
  lang="compiler-error"
  src={nonExhaustiveMatchingStderr}
  focusOn="1+20"
/>

---

# Some things that Rust's type system cannot do

- Refinement types
- Dependent types

---

# Refinement types

```rust
// Invalid syntax
fn divide(a: i32, b: i32 ∌ {0}) -> i32 {
    a / b
}
```

---

# Refinement type replacements

### Create a newtype

import pseudoRefinementTypes from "rust:reliable-code-using-types/pseudo-refinement-types";

<Transclude src={pseudoRefinementTypes} focusOn="1+14" />

<Notes />

- This has a weakness - `.0` is modifiable

---

# Refinement type replacements

### Use an existing newtype

import pseudoRefinementTypesRev1 from "rust:reliable-code-using-types/pseudo-refinement-types?rev=1";

<Transclude src={pseudoRefinementTypesRev1} focusOn="1+5" />

<Notes />

- Non-zero types are built-in as they enable optimizations

---

# Dependent types

```rust
// Invalid syntax
fn start_game(players: usize) -> [Player; players] {
    // ...
}
```

<Notes />

- Types are dependent on a value

---

# Dependent type replacements

### Abandon it

import pseudoDependentTypes from "rust:reliable-code-using-types/pseudo-dependent-types";

<Transclude src={pseudoDependentTypes} focusOn="1+2,4" />

<Notes />

- Can be combined with a newtype to enforce some restrictions

---

# Dependent type replacements

### Use const generics

import pseudoDependentTypesRev1 from "rust:reliable-code-using-types/pseudo-dependent-types?rev=1";

<Transclude src={pseudoDependentTypesRev1} focusOn="1+2,4" />

<Notes />

- Not a direct replacement, but might fit in some cases

---

# Be pragmatic

- Adding types can increase the clarity of your code

<Continue />

- Adding types can decrease the clarity of your code

<Notes />

- Carol's opinion of Haskell: "it's only types, no code"

<Continue />

- Apply these techniques where you get the most benefit
  - Correctness-critical
  - Widely used
  - Performance
  - Memory safety

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
