import { useCallback } from "react";

import { useAppDispatch } from "./app/hooks";
import { setDeck } from "./features/route/routeSlice";
import { setSlideIndex } from "./features/deck/deckSlice";
import { broadcast } from "./app/broadcast";
import { DeckId } from "./decks";

interface ChangeDeckProps {
  deck: DeckId;
  children: React.ReactNode;
}

const ChangeDeck: React.FC<ChangeDeckProps> = ({ children, deck }) => {
  const dispatch = useAppDispatch();
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      dispatch(broadcast(setDeck(deck), setSlideIndex(1)));
    },
    [dispatch, deck],
  );

  const url = new URL(window.location.toString());
  url.searchParams.set("deck", deck);
  url.searchParams.set("page", (1).toString());

  return (
    <a href={url.toString()} onClick={onClick}>
      {children}
    </a>
  );
};

export default ChangeDeck;
