.container {
  background-color: rgb(100 100 100);
}

@media screen {
  .container {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }
}
