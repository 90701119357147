.-center {
  display: grid;
  grid-template-columns: minmax(0, auto);
  grid-template-rows: minmax(0, auto);
  place-items: center;
}

.-expand {
  width: 100%;
  height: 100%;
}

.-fullImage {
  composes: -expand;
  max-width: calc(var(--slide-target-width) * 0.9);
  max-height: calc(var(--slide-target-height) * 0.9);
  padding: 0 2em;
  object-fit: contain;
}

.-chunkyImage {
  display: block;
  margin: 0 auto;
}
