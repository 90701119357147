import Transclude, { TranscludeProps } from "./Transclude";
import React from "react";

type ExcerptProps = Pick<TranscludeProps, "emphasize">;
const excerpt = (
  src: TranscludeProps["src"],
  focusOn: TranscludeProps["focusOn"],
  extra: {
    transform?: TranscludeProps["transform"];
    insert?: TranscludeProps["insert"];
  } = {},
) => {
  const Excerpt: React.FC<ExcerptProps> = ({ emphasize }) => (
    <Transclude
      src={src}
      focusOn={focusOn}
      emphasize={emphasize}
      transform={extra.transform}
      insert={extra.insert}
    />
  );
  return Excerpt;
};

// ----------

import allocString from "rust:extracted/alloc/src/string.rs";

export const String = excerpt(allocString, "365+3");
export const StringFromUtf8 = excerpt(allocString, "427,563,568,1975", {
  insert: { 3: "        // ..." },
});

// ----------

import coreFutureFuture from "rust:extracted/core/src/future/future.rs";

export const FutureTrait = excerpt(coreFutureFuture, "36,40+2,104+2");

// ----------

import coreIterTraitsIterator from "rust:extracted/core/src/iter/traits/iterator.rs";

export const IteratorTrait = excerpt(
  coreIterTraitsIterator,
  "75,79+2,113,4129",
);

export const IteratorByRef = excerpt(coreIterTraitsIterator, "75,1930+3,4129");
export const IteratorCollect = excerpt(
  coreIterTraitsIterator,
  "75,2050+3,4129",
);
export const IteratorCycle = excerpt(coreIterTraitsIterator, "75,3559+3,4129");
export const IteratorFilter = excerpt(coreIterTraitsIterator, "75,924+4,4129");
export const IteratorMap = excerpt(coreIterTraitsIterator, "75,802+4,4129");
export const IteratorRev = excerpt(coreIterTraitsIterator, "75,3404+3,4129");
export const IteratorRposition = excerpt(
  coreIterTraitsIterator,
  "75,3156+4,4129",
);

// ----------

import coreIterTraitsCollect from "rust:extracted/core/src/iter/traits/collect.rs";

export const IntoIteratorTrait = excerpt(
  coreIterTraitsCollect,
  "240,243,247+2,268+2",
);

// ----------

import coreOption from "rust:extracted/core/src/option.rs";

export const OptionEnum = excerpt(coreOption, "570,574,578+2", {
  transform: { 3: [/#\[.*\] /, ""] },
});

// ----------

import coreSliceMod from "rust:extracted/core/src/slice/mod.rs";

export const SliceSplitAtMut = excerpt(coreSliceMod, "1904+6");
export const SliceSplitAtMutChecked = excerpt(coreSliceMod, "2112+2,2116+5");
export const SliceSplitAtMutUnchecked = excerpt(coreSliceMod, "2014+9,2027+2");

// ----------

import coreSliceRaw from "rust:extracted/core/src/slice/raw.rs";

export const SliceFromRawParts = excerpt(coreSliceRaw, "94,104", {
  insert: { 2: "    // ..." },
});
export const SliceFromRawPartsMut = excerpt(coreSliceRaw, "141,151", {
  insert: { 2: "    // ..." },
});

// ----------

import coreStrConverts from "rust:extracted/core/src/str/converts.rs";

export const StrFromUtf8 = excerpt(coreStrConverts, "88,97", {
  insert: { 2: "    // ..." },
});

// ----------

import coreStrIter from "rust:extracted/core/src/str/iter.rs";

export const CharsIterator = excerpt(coreStrIter, "141,162,165+2", {
  insert: { 3: "        // ..." },
});

// ----------

import coreTaskPoll from "rust:extracted/core/src/task/poll.rs";

export const PollEnum = excerpt(coreTaskPoll, "12,16,25+2", {
  transform: { 2: [/#\[.*\] /, ""] },
});

// ----------

import stdThreadMod from "rust:extracted/std/src/thread/mod.rs";

export const ThreadSpawn = excerpt(stdThreadMod, "678+5");

// ----------

import stdThreadScoped from "rust:extracted/std/src/thread/scoped.rs";

export const ThreadScopedScope = excerpt(stdThreadScoped, "129-131");
export const ThreadScopedSpawn = excerpt(stdThreadScoped, "163,189-192,196");

// ----------

import futuresCoreFuture from "rust:extracted/futures-core-0.3.30/src/future.rs";

export const TryFutureTrait = excerpt(futuresCoreFuture, "58,60,63+2,70+2");

// ----------

import futuresCoreStream from "rust:extracted/futures-core-0.3.30/src/stream.rs";

export const StreamTrait = excerpt(futuresCoreStream, "27,29+2,66,98");
export const TryStreamTrait = excerpt(futuresCoreStream, "167,169,172+2,179+5");

// ----------

import futuresIo from "rust:extracted/futures-io-0.3.30/src/lib.rs";

export const FuturesIoAsyncReadPollRead = excerpt(futuresIo, "60+5");

// ----------

import futuresUtilIoMod from "rust:extracted/futures-util-0.3.30/src/io/mod.rs";

export const FuturesAsyncReadExtRead = excerpt(futuresUtilIoMod, "204+3");

// ----------

import futuresUtilFutureFutureMod from "rust:extracted/futures-util-0.3.30/src/future/future/mod.rs";

export const FutureExtTrait = excerpt(futuresUtilFutureFutureMod, "123,606", {
  insert: { 2: "    // 19 methods" },
});

// ----------

import futuresUtilFutureTryFutureMod from "rust:extracted/futures-util-0.3.30/src/future/try_future/mod.rs";

export const TryFutureExtTrait = excerpt(
  futuresUtilFutureTryFutureMod,
  "137,625",
  {
    insert: { 2: "    // 16 methods" },
  },
);

// ----------

import futuresUtilStreamStreamMod from "rust:extracted/futures-util-0.3.30/src/stream/stream/mod.rs";

export const StreamExtTrait = excerpt(futuresUtilStreamStreamMod, "249,1697", {
  insert: { 2: "    // 46 methods" },
});

// ----------

import futuresUtilStreamTryStreamMod from "rust:extracted/futures-util-0.3.30/src/stream/try_stream/mod.rs";

export const TryStreamExtTrait = excerpt(
  futuresUtilStreamTryStreamMod,
  "180,1251",
  {
    insert: { 2: "    // 29 methods" },
  },
);

// ----------

import synDerive from "rust:extracted/syn-2.0.58/src/derive.rs";

export const DeriveInput = excerpt(synDerive, "12-18");
export const Data = excerpt(synDerive, "30-34");
export const DataStruct = excerpt(synDerive, "40-44");

// ----------

import tokioIoAsyncRead from "rust:extracted/tokio-1.37.0/src/io/async_read.rs";

export const TokioAsyncReadPollRead = excerpt(tokioIoAsyncRead, "53+5");

// ----------

import tokioIoUtilAsyncReadExt from "rust:extracted/tokio-1.37.0/src/io/util/async_read_ext.rs";

export const TokioAsyncReadExtRead = excerpt(tokioIoUtilAsyncReadExt, "168+3");
