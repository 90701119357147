.outer {
  composes: -expand from "../util.module.css";
}

.-inner {
  width: var(--slide-target-width);
  height: var(--slide-target-height);
  transform-origin: top left;
}

.inner4x3 {
  composes: -inner;

  --slide-target-width: 1600px;
  --slide-target-height: 1200px;
}

.inner16x9 {
  composes: -inner;

  --slide-target-width: 1600px;
  --slide-target-height: 900px;
}

@media print {
  .outer:has(.inner16x9) {
    width: 800px;
    height: 450px;
  }

  .outer:has(.inner4x3) {
    width: 800px;
    height: 600px;
  }

  .-inner {
    scale: 0.5;
  }
}
