import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";
import Quote from "./Quote";

export const title = "Coming to Rust from dynamic languages";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# Coming to Rust <br/> from dynamic languages

---

# Statically vs dynamically typed

<Notes />

- What does this mean to people?

<Continue />

### Statically typed

- Types are assigned at compile time, based on the source code

### Dynamically typed

- Types are assigned at run time, based on the value

<Notes />

- Most languages aren't completely in one side or the other; Rust has some dynamic abilities
- There's also strongly vs. weakly typed

---

# Types of variables

### JavaScript

```javascript
const name = Math.random() < 0.5 ? "Vivian" : 42;
```

<Notes />

- What is the type of `name`? "It depends"

<Continue />

### Rust

import typesOfVariables from "rust:from-dynamic-languages/types-of-variables";

<Transclude src={typesOfVariables} />

<Continue />

import typesOfVariablesStderr from "stderr:from-dynamic-languages/types-of-variables";

<Transclude lang="compiler-error" src={typesOfVariablesStderr} focusOn="1+8" />

<Notes />

- Can't say "it depends" in Rust

---

# Rust variables must have a single type

### Enums

import singleTypeEnum from "rust:from-dynamic-languages/single-type-enum";

<Transclude src={singleTypeEnum} />

<Notes />

- The type of `name` is now the `Name` enum. Enum variants are not their own type.
- Enums know which variant they are
- Great when you have a fixed set of choices

---

# Rust variables must have a single type

### Trait objects

import singleTypeTraitObject from "rust:from-dynamic-languages/single-type-trait-object";

<Transclude src={singleTypeTraitObject} />

<Notes />

- Explicit type on the variable, `Box::new` on each instance
- Boxed like this requires heap allocation
- Great when you have an unbounded set of choices

---

import exerciseHeterogenousCollection from "rust:from-dynamic-languages/exercise-heterogenous-collection";

# Exercise

- Create a vector "containing multiple types"
  - e.g. an integer, a boolean, and a string
- Print out the vector
  - <Transclude
      src={exerciseHeterogenousCollection}
      focusOn="4+1"
      copyAllCode={false}
    />

## Bonus

- Use an enum instead of a trait object or vice versa

---

# One answer

<Continue />

<Transclude src={exerciseHeterogenousCollection} />

---

# Another answer

import exerciseHeterogenousCollectionRev1 from "rust:from-dynamic-languages/exercise-heterogenous-collection?rev=1";

<Transclude src={exerciseHeterogenousCollectionRev1} />

---

# Reusing code

### JavaScript

```javascript
class Dog {
  make_noise() {
    console.log("woof");
  }
}

class Cat {
  make_noise() {
    console.log("meow");
  }
}

const hug = (animal) => {
  animal.make_noise();
};

hug(new Dog());
hug(new Cat());
```

<Notes />

- Commonly called duck-typing

---

# Reusing code

import reusingCode from "rust:from-dynamic-languages/reusing-code";

<Transclude src={reusingCode} />

<Notes />

- Can immediately see that it's bigger

---

# Reusing code

<Transclude src={reusingCode} emphasize="1,2,3" />

<Notes />

- Define a trait

---

# Reusing code

<Transclude src={reusingCode} emphasize="6,7,8,9,10,11,13,14,15,16,17,18" />

<Notes />

- Implement the trait

---

# Reusing code

<Transclude src={reusingCode} emphasize="19[15+11]" />

<Notes />

- Restrict arguments to the function via generics

---

# Choosing how code is generated

### Static dispatch

<Transclude
  src={reusingCode}
  focusOn="19+8"
  emphasize="1[15+11],6[8+3],7[8+3]"
/>

<Notes />

- Compiler generates code specific to each concrete type passed in
- Can use that information to highly optimize

<Continue />

### Dynamic dispatch

import reusingCodeRev1 from "rust:from-dynamic-languages/reusing-code?rev=1";

<Transclude
  src={reusingCodeRev1}
  focusOn="19+8"
  emphasize="1[15+11],6[8+4],7[8+4]"
/>

<Notes />

- Still a trait object, but behind a reference here, not a `Box`
- Compiler generates one function
- Can't be optimized as much
- Reduces total amount of code generated

---

# Enums are dynamic dispatch too

import enumDynamicDispatch from "rust:from-dynamic-languages/enum-dynamic-dispatch";

<Transclude src={enumDynamicDispatch} />

<Notes />

- The `match` checks the enum variant and calls the appropriate branch

---

# Limits of trait objects

## Object safety

- Cannot require that `Self` be `Sized`
  - e.g. `Clone`
  - This includes taking `self` by value
- Cannot have an associated function
  - e.g. `Default`
- Cannot use a generic as an argument or return value
  - e.g. `Hash`
  - This includes `Self`

---

# Not-yet-written code

### JavaScript

```javascript
class Animal {
  shouldFrolic() {
    return false; // FIXME: check the frolyq API
  }

  dance() {
    if (this.shouldFrolic()) {
      this.frolic();
    }
  }
}

new Animal().dance();
```

---

# Not-yet-written code

### Rust

import notYetWritten from "rust:from-dynamic-languages/not-yet-written";

<Transclude src={notYetWritten} focusOn="1+3,6+14" />

<Continue />

import notYetWrittenStderr from "stderr:from-dynamic-languages/not-yet-written";

<Transclude lang="compiler-error" src={notYetWrittenStderr} focusOn="12+5" />

---

# Functions without bodies

<Transclude src={notYetWritten} focusOn="1+4,15+1" />

<Continue />

<Transclude lang="compiler-error" src={notYetWrittenStderr} focusOn="1+11" />

---

# Use `todo!` or `unimplemented!` for placeholder code

import notYetWrittenRev1 from "rust:from-dynamic-languages/not-yet-written?rev=1";

<Transclude src={notYetWrittenRev1} focusOn="1+21" emphasize="5,9" />

---

# Introspection / Reflection

- The ability to view information about the system
  - Types / methods
  - Memory usage, open files
  - What source file defined some piece of code
- The ability to create or modify that information
  - New types or methods
  - Decorators, loggers, contracts

---

# Serialization

### JavaScript

```javascript
class Animal {
  name = "Leo";
  age = 3;
}

console.log(JSON.stringify(new Animal()));
```

<Continue />

```none
{"name":"Leo","age":3}
```

---

# Serialization

### Rust

import serialization from "rust:from-dynamic-languages/serialization";

<Transclude src={serialization} />

<Continue />

import serializationStderr from "stderr:from-dynamic-languages/serialization";

<Transclude lang="compiler-error" src={serializationStderr} focusOn="1+8" />

---

# Code generation via macros

import serializationRev1 from "rust:from-dynamic-languages/serialization?rev=1";

<Transclude src={serializationRev1} emphasize="1,3" />

<Notes />

- We've already seen this for `derive(Debug)`
- This steps through the struct's fields and serializes each in turn

---

# Macros

- Run by the compiler while your code is compiling
- Two kinds
  - Declarative macros
  - Procedural macros

---

# Declarative macros

import macrosDecl from "rust:from-dynamic-languages/macros-decl";

<Transclude src={macrosDecl} />

<Notes />

- Written inside of your code
- Can be used to create new control flow based on existing
- e.g. the `try` macro
- Smarter than C macros, probably not quite as powerful as Lisp macros

---

# Procedural macros

import macrosProc from "rust:from-dynamic-languages/macros-proc";

<Transclude src={macrosProc} focusOn="5+7" />

<Notes />

- Written in a separate crate
- Parses the related Rust code
- Generates additional or replacement code

---

# Code generation via build scripts

- Runs before your code is compiled
- Separate Rust binary
- Can interact with just about anything
  - Cargo
  - Filesystem
  - Network
  - C compiler

---

# Systems languages give you choices and control

- Static vs dynamic dispatch
- Details of representations
- Location of values

<Notes />

- This also means that you have to _make_ those choices

---

# Value representations

- It can be important how many bytes a value takes up
  - `u8` / `u16` / `u32` / `u64` / `u128`
- It can be important what kinds of values it can hold
  - `u32` / `i32` / `f32`
- It can be important how a value is stored in memory
  - Interfacing with hardware registers

---

# The stack vs. the heap

- Stack
  - Efficient access patterns
  - Good cache locality
  - Requires static knowledge of values
- Heap
  - Access patterns can vary widely
  - Cache locality can vary widely
  - Allows very dynamic allocation

<Notes />

- `LinkedList` and `Vec` both use the heap, but linked lists are terrible

---

# The stack vs. the heap

import stackVsHeap from "rust:from-dynamic-languages/stack-vs-heap";

<Transclude src={stackVsHeap} />

---

# The stack vs. the heap

<Transclude src={stackVsHeap} emphasize="2[32+15]" />

At least 13 bytes are on the heap

---

# The stack vs. the heap

<Transclude src={stackVsHeap} emphasize="2[8+8]" />

24 bytes are on the stack

<Notes />

- For 64-bit architecture; 12 on 32-bit
- Can use `mem::size_of` to find this out

---

# Everything on the stack has a known size

import unsizedValues from "rust:from-dynamic-languages/unsized-values";

<Transclude src={unsizedValues} focusOn="1+3" />

<Continue />

import unsizedValuesStderr from "stderr:from-dynamic-languages/unsized-values";

<Transclude lang="compiler-error" src={unsizedValuesStderr} focusOn="1+14" />

---

# Object-oriented design

- Rust is a great object-oriented language

<Continue />

- Depending on your definitions of "object" and "object-oriented"
- Many of the entries from _Design Patterns_ apply

<Notes />

- "fun" facts
  - patterns basically exist to work around limitations/shortcomings
  - No real agreed on definition of what object-oriented means
    - I prefer Alan Kay's definition of message passing and cells

---

# Rust does not have inheritance

<Quote caption="Design Patterns">Favor composition over inheritance</Quote>

<Notes />

- Really easy to follow this advice when there is no inheritance
- Unfortunately, there's not great delegation syntax either

---

# Overriding and reusing a parent method

```javascript
class Calculations {
  log_info() {
    console.log(">>> " + this.required() + " <<<");
  }
}

class Example extends Calculations {
  required() {
    return 42;
  }

  log_info() {
    console.log("More info");
    super.log_info();
  }
}

new Example().log_info();
```

---

# Overriding and reusing a default trait method

import overrideReuseDefaultMethod from "rust:from-dynamic-languages/override-reuse-default-method";

<Transclude src={overrideReuseDefaultMethod} />

---

# Overriding and reusing a provided trait method

import overrideReuseDefaultMethodRev1 from "rust:from-dynamic-languages/override-reuse-default-method?rev=1";

<Transclude src={overrideReuseDefaultMethodRev1} emphasize="5,9,10,11,22" />

<Notes />

- No amazing solution here; just extract a function
- Likely better to reorganize the code

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
