import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Mode = "display" | "presenter" | "print";
type AspectRatio = "16x9" | "4x3";

export interface DeckState {
  slideIndex: number;
  slideCount: number;
  mode: Mode;
  aspectRatio: AspectRatio;
}

const initialState: DeckState = {
  slideIndex: 1,
  slideCount: 0,
  mode: "display",
  aspectRatio: "16x9",
};

export const deckSlice = createSlice({
  name: "deck",
  initialState,
  reducers: {
    forward: (state) => {
      const { slideCount, slideIndex } = state;
      state.slideIndex = Math.min(slideCount, slideIndex + 1);
    },
    backward: (state) => {
      const { slideIndex } = state;
      state.slideIndex = Math.max(1, slideIndex - 1);
    },
    firstSlide: (state) => {
      state.slideIndex = 1;
    },
    lastSlide: (state) => {
      const { slideCount } = state;
      state.slideIndex = slideCount;
    },
    setSlideIndex: (state, action: PayloadAction<number>) => {
      state.slideIndex = action.payload;
    },
    setSlideCount: (state, action: PayloadAction<number>) => {
      state.slideCount = action.payload;
    },
    togglePresenter: (state) => {
      if (state.mode === "presenter") {
        state.mode = "display";
      } else {
        state.mode = "presenter";
      }
    },
    enablePrint: (state) => {
      state.mode = "print";
    },
    disablePrint: (state) => {
      state.mode = "display";
    },
    toggleAspectRatio: (state) => {
      if (state.aspectRatio === "16x9") {
        state.aspectRatio = "4x3";
      } else {
        state.aspectRatio = "16x9";
      }
    },
  },
});

export const {
  forward,
  backward,
  firstSlide,
  lastSlide,
  setSlideIndex,
  setSlideCount,
  togglePresenter,
  enablePrint,
  disablePrint,
  toggleAspectRatio,
} = deckSlice.actions;

export default deckSlice.reducer;
