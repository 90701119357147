.dialog {
  &::backdrop {
    background: rgb(0 0 0 / 50%);
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.title {
  padding: 0;
  margin: 0;
  font-size: 1.5em;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 0 1px 1px;
  aspect-ratio: 1 / 1;
}

.sets {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.set {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5em;
}

.keycaps {
  display: flex;
  gap: 0.25em;
}

/* https://chrispennington.blog/blog/add-styling-to-keyboard-keys-css/ */
.keycap {
  display: inline-block;
  padding: 3px 5px;
  border: 1.5px solid black;
  box-shadow: 1.5px 1.5px rgb(0 0 0 / 25%);
  line-height: 0.85em;
  white-space: nowrap;
}
