import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";

import { StrFromUtf8, String, StringFromUtf8 } from "./Excerpts";

export const title = "Strings vs bytes";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# Strings vs bytes

---

# History

- ASCII - 1963

<Notes />

- Basic English text (letters, numbers, punctuation, control chars)

<Continue />

- Unicode - 1991 / 1996

<Notes />

- Big change in 2.0

<Continue />

- UTF-8 - 1993

<Notes />

- How we store Unicode as bytes
- Has the nice advantage of being a superset of ASCII

---

# Rust strings

- UTF-8
- Know their length
- Not NUL-terminated

---

# Strings are just fancy byte arrays

<String />

<Notes />

- "Fancy" because they ensure that the bytes are always valid UTF-8

---

# Strings are bytes

```rust
impl str {
    pub fn as_bytes(&self) -> &[u8] { /* ... */ }
}
```

```rust
impl String {
    pub fn into_bytes(self) -> Vec<u8> { /* ... */ }
}
```

<Notes />

- Remember that `&String` can be treated as a `&str`

---

# Bytes are sometimes strings

<StrFromUtf8 />

<StringFromUtf8 />

<Notes />

- Don't know why `str` is a free function and not associated function.

---

# Interacting with byte slices

- Standard library is a bit weak for some of this
  - Work being done to improve the `Pattern` / `Needle` / `Haystack` API
- Regex
- Jetscii
- Other crates

---

# Regex

import useRegexToml from "toml:strings-vs-bytes/use-regex";
import useRegex from "rust:strings-vs-bytes/use-regex";

<Transclude src={useRegexToml} lang="toml" focusOn="8+2" />

<Transclude src={useRegex} />

---

# Jetscii

import useJetsciiToml from "toml:strings-vs-bytes/use-jetscii";
import useJetscii from "rust:strings-vs-bytes/use-jetscii";

<Transclude src={useJetsciiToml} lang="toml" focusOn="8+2" />
<Transclude src={useJetscii} />

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
