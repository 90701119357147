.container {
  padding: 1em;
  margin: 0;
  background: palegoldenrod;
}

.quote {
  margin: 0;
  font-size: 2.5rem;
}

.caption {
  font-style: italic;
  text-align: right;

  &::before {
    content: "— ";
  }
}
