import { useCallback } from "react";

import { useAppSelector } from "./app/hooks";
import Prism from "./Prism";

import * as styles from "./Transclude.module.css";

const leadingWhitespace = (str: string) => {
  const match = str.match(/^\s+/);
  return match ? match[0].length : 0;
};

const minOfTwo = (a: number, b: number) => Math.min(a, b);

const parseBase10 = (s: string) => parseInt(s, 10);

const oneBasedLineIndex = (n: number) => n - 1;

const parseOneBasedLine = (s: string) => oneBasedLineIndex(parseBase10(s));

type FocusOnParser = (_: string) => [number, number];

const parseExplicitRange: FocusOnParser = (focusOn) => {
  const [innerStart, innerEnd = innerStart]: number[] = focusOn
    .split("-")
    .map(parseOneBasedLine);

  return [innerStart, innerEnd + 1];
};

const parseWidthRange: FocusOnParser = (focusOn) => {
  const [innerStartStr, innerWidthStr] = focusOn.split("+");

  const innerStart = parseOneBasedLine(innerStartStr);
  const innerWidth = innerWidthStr ? parseBase10(innerWidthStr) : 1;

  return [innerStart, innerStart + innerWidth];
};

const applyFocus = (src: string, focusOn: string) => {
  const lines = src.split("\n");

  const focusedLines = focusOn.split(",").flatMap((focusCommand) => {
    const parser = focusCommand.includes("-")
      ? parseExplicitRange
      : parseWidthRange;
    const [start, end] = parser(focusCommand);

    return lines.slice(start, end);
  });

  if (focusedLines.length === 0) {
    throw "The focus resulted in no lines";
  }

  const spaceToRemove = focusedLines
    .filter((l) => l.length > 0)
    .map(leadingWhitespace)
    .reduce(minOfTwo);

  return focusedLines.map((l) => l.slice(spaceToRemove)).join("\n");
};

type Transforms = Record<number, [RegExp, string]>;

const applyTransform = (src: string, transforms: Transforms) => {
  const lines = src.split("\n");

  for (const userLineNumberS in transforms) {
    const userLineNumber = parseBase10(userLineNumberS);
    const [find, replace] = transforms[userLineNumber];

    const lineNumber = oneBasedLineIndex(userLineNumber);
    lines[lineNumber] = lines[lineNumber].replace(find, replace);
  }

  return lines.join("\n");
};

type Inserts = Record<number, string>;

const applyInsert = (src: string, inserts: Inserts) => {
  const lines = src.split("\n");

  // Apply inserts backwards to preserve line numbers
  const lineNumbers = Object.keys(inserts)
    .map(parseBase10)
    .sort((a, b) => b - a);

  lineNumbers.forEach((userLineNumber) => {
    const inserted = inserts[userLineNumber];

    const lineNumber = oneBasedLineIndex(userLineNumber);
    lines.splice(lineNumber, 0, inserted);
  });

  return lines.join("\n");
};

// https://stackoverflow.com/a/59322890/155423
const toWindows = <T,>(inputArray: T[], size: number) =>
  Array.from(
    { length: inputArray.length - (size - 1) }, // get the appropriate length
    (_, index) => inputArray.slice(index, index + size), // create the windows
  );

interface EmphasisSpec {
  line: number;
  columns?: [number, number];
}

const applyEmphasis = (src: string, emphasize: string) => {
  const highlightLines: EmphasisSpec[] = emphasize
    .split(",")
    .map((highlightSpec) => {
      // LINE
      // LINE[COLSTART-COLEND]
      // LINE[COLSTART+COLWIDTH]
      const match = highlightSpec.match(/(\d+)\[([^\]]+)\]/);
      if (match) {
        const [_, lineTxt, body] = match;
        const line = parseOneBasedLine(lineTxt);

        if (body.includes("-")) {
          const [start, end = start + 1]: number[] = body
            .split("-")
            .map(parseBase10);
          return { line, columns: [start, end] };
        } else {
          const [start, width = 1] = body.split("+").map(parseBase10);
          return { line, columns: [start, start + width] };
        }
      } else {
        const line = parseOneBasedLine(highlightSpec);
        return { line };
      }
    });

  let lineOffset = 0;
  const lineOffsets: [number, number][] = [];
  for (const match of src.matchAll(/\n/g)) {
    if (match.index) {
      const newlineOffset = match.index + 1;
      lineOffsets.push([lineOffset, newlineOffset]);
      lineOffset = newlineOffset;
    }
  }
  // If our input string doesn't end in a newline, include it
  lineOffsets.push([lineOffset, src.length]);

  const offsetsForHighlight = ({ line, columns }: EmphasisSpec) => {
    const offsets = lineOffsets[line];
    const [lineStart, _] = offsets;

    if (columns) {
      return columns.map((c) => c + lineStart);
    } else {
      return offsets;
    }
  };

  const locations = [0];
  for (const highlightLine of highlightLines) {
    locations.push(...offsetsForHighlight(highlightLine));
  }
  locations.push(src.length);

  let addEmphasis = false;
  return toWindows(locations, 2).map(([a, b], idx) => {
    const chunk = src.slice(a, b);
    const value = addEmphasis ? <mark key={idx}>{chunk}</mark> : chunk;
    addEmphasis = !addEmphasis;
    return value;
  });
};

export interface TranscludeProps {
  src?: string;
  focusOn?: string;
  transform?: Transforms;
  insert?: Inserts;
  emphasize?: string;
  copyAllCode?: boolean;
  lang?: "rust" | "c" | "toml" | "compiler-error";
  children?: React.ReactNode;
}

const Transclude: React.FC<TranscludeProps> = ({
  children,
  src,
  focusOn,
  transform,
  insert,
  emphasize,
  copyAllCode = true,
  lang = "rust",
}) => {
  const meta = useAppSelector((state) => state.keyboard.meta);

  let originalCode = "";

  if (typeof children == "string") {
    originalCode = children;
  }

  if (src) {
    originalCode = src;
  }

  let manipulatedCode = originalCode;

  if (focusOn) {
    manipulatedCode = applyFocus(manipulatedCode, focusOn);
  }

  if (transform) {
    manipulatedCode = applyTransform(manipulatedCode, transform);
  }

  if (insert) {
    manipulatedCode = applyInsert(manipulatedCode, insert);
  }

  let formattedCode: React.ReactNode = manipulatedCode;
  if (emphasize) {
    formattedCode = applyEmphasis(manipulatedCode, emphasize);
  }

  const outerClassName = meta ? styles.hovered : "";
  const className = `language-${lang}`;

  const codeToCopy = copyAllCode ? originalCode : manipulatedCode;

  const doCopy = useCallback(() => {
    navigator.clipboard.writeText(codeToCopy);
  }, [codeToCopy]);

  const Content = ["rust", "toml"].includes(lang) ? Prism : "code";

  return (
    <div className={outerClassName}>
      <pre className={className}>
        <Content className={className}>{formattedCode}</Content>
      </pre>
      <button className={styles.copy} onClick={doCopy}>
        Copy
      </button>
    </div>
  );
};

export default Transclude;
