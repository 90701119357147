.outer {
  composes: -center from "../util.module.css";
  composes: -expand from "../util.module.css";
}

.container {
  composes: -expand from "../util.module.css";
  position: relative;
  padding: 2rem 4rem;
  background-color: white;
  font-size: 1.5rem;

  & code[class*="language-"],
  & pre[class*="language-"] {
    font-size: 1.15rem;
    line-height: 1.2;
  }

  & h1 {
    margin: 0 0 2rem;

    /* Prevents slight differnences when switching between slides with and
     * without code in the header */
    & code {
      margin: 0;
      line-height: 1;
    }
  }

  & ul,
  & ol {
    font-size: 1.75rem;
  }

  & li {
    margin: 1.5rem 0;
  }
}

.pageNumber {
  position: absolute;
  right: 1em;
  bottom: 1em;
  color: rgb(160 160 160);
}

@media print {
  .pageNumber {
    display: none;
  }
}
