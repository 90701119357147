import { useEffect, useRef } from "react";
import RealPrism from "./prism-shim";

interface PrismProps {
  className?: string;
  children?: React.ReactNode;
}

const Prism: React.FC<PrismProps> = ({ children, className }) => {
  const codeRef = useRef<HTMLElement | null>(null);
  const highlightRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const code = codeRef.current;
    const highlight = highlightRef.current;

    if (code && highlight) {
      const codeChildren = code.cloneNode(true).childNodes;
      highlight.replaceChildren(...codeChildren);

      code.style.display = "none";
      RealPrism.highlightElement(highlight);
    }
  }, [codeRef, highlightRef, children, className]);

  return (
    <>
      <code className={className} ref={codeRef}>
        {children}
      </code>
      <code className={className} ref={highlightRef} />
    </>
  );
};

export default Prism;
