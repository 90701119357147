import { useEffect, useRef } from "react";

import { useAppSelector } from "../app/hooks";

import * as styles from "./Scaler.module.css";

// https://stackoverflow.com/questions/61533780/
const applyScaling = (outer: HTMLDivElement, inner: HTMLDivElement) => {
  // Reset the values to get the unmodified bounds
  inner.style.transform = "scale(1)";
  outer.style.width = "";
  outer.style.height = "";

  const { width: ow, height: oh } = outer.getBoundingClientRect();
  const { width: iw, height: ih } = inner.getBoundingClientRect();

  const widthScale = ow / iw;
  const heightScale = oh / ih;
  const scaleFactor = Math.min(widthScale, heightScale);
  inner.style.transform = `scale(${scaleFactor})`;

  // Set the outer size explicitly to allow parents to position it accurately
  const { width: iwUpdated, height: ihUpdated } = inner.getBoundingClientRect();
  outer.style.width = `${iwUpdated}px`;
  outer.style.height = `${ihUpdated}px`;
};

interface ScalerProps {
  children?: React.ReactNode;
}

const Scaler: React.FC<ScalerProps> = ({ children }) => {
  const outer = useRef<HTMLDivElement>(null);
  const inner = useRef<HTMLDivElement>(null);

  const isPrint = useAppSelector((state) => state.deck.mode === "print");

  const aspectRatio = useAppSelector((state) => state.deck.aspectRatio);
  const aspectClass =
    aspectRatio === "16x9" ? styles.inner16x9 : styles.inner4x3;

  useEffect(() => {
    if (isPrint) {
      return;
    }

    const maybeApplyScaling = () => {
      if (outer.current && inner.current) {
        applyScaling(outer.current, inner.current);
      }
    };

    maybeApplyScaling();

    const observer = new ResizeObserver(maybeApplyScaling);

    // When the thing containing us resizes, we need to adjust our scaling
    const parent = outer?.current?.parentElement;
    if (parent) {
      observer.observe(parent);
    }
    // When the aspect ratio changes, we need to adjust our scaling
    const content = inner?.current;
    if (content) {
      observer.observe(content);
    }

    return () => observer.disconnect();
  }, [outer, inner, isPrint]);

  return (
    <div ref={outer} className={styles.outer}>
      <div ref={inner} className={aspectClass}>
        {children}
      </div>
    </div>
  );
};

export default Scaler;
