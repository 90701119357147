import { createSlice } from "@reduxjs/toolkit";

export interface HelpState {
  enabled: boolean;
}

const initialState: HelpState = {
  enabled: false,
};

export const helpSlice = createSlice({
  name: "help",
  initialState,
  reducers: {
    close: (state) => {
      state.enabled = false;
    },
    toggle: (state) => {
      state.enabled = !state.enabled;
    },
  },
});

export const { close, toggle } = helpSlice.actions;

export default helpSlice.reducer;
