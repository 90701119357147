import { Notes, Continue, SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import Splash from "./layout/Splash";
import TestPattern from "./TestPattern";
import Transclude from "./Transclude";
import Quote from "./Quote";

import * as styles from "./testing.module.css";

export const title = "Testing";

<SlideLayout use={TestPattern} />

---

<SlideLayout use={Splash} />

# Testing

---

# Writing a test

Built-in simple testing framework and assertions

import oneTest from "rust:testing/one-test";

<Transclude src={oneTest} />

---

# Writing a test

Built-in simple testing framework and assertions

<Transclude src={oneTest} emphasize="5" />

<Notes />

- Adding the `#[test]` attribute to a function will cause it to be run as a test
- Must take no arguments

---

# Writing a test

Built-in simple testing framework and assertions

<Transclude src={oneTest} emphasize="7" />

<Notes />

- There's also `assert` and `assert_ne`.
- Any panic will cause the test to fail

---

# Running a test

import oneTestStdout from "stdout:testing/one-test";

<Transclude
  src={oneTestStdout}
  lang="compiler-output"
  focusOn="2+4"
  insert={{ 1: "% cargo test\n" }}
/>

<Notes />

- `cargo test` has a myriad of options, helpful to run `cargo test --help`

---

# Unit tests vs. integration tests

- Unit tests

  - Small
  - Fast
  - Isolated

- Integration tests
  - Larger
  - Slower
  - Many parts

<Notes />

- This is a complicated and nuanced area
- Both are subset of _functional_ tests; there's also non-functional tests

---

# Rust mechanics for unit tests vs. integration tests

- Unit tests

  - In the same file as the unit being tested

- Integration tests
  - Separate file in the `tests` directory

<Notes />

- These are general guidelines
  - You could write unit tests in a separate module / file
  - You could write a completely different crate to test a library

---

# Writing a unit test

import writingUnitTest from "rust:testing/writing-unit-test";

<Transclude src={writingUnitTest} />

---

# Writing a unit test

<Transclude src={writingUnitTest} emphasize="6" />

<Notes />

- Put tests in a module. Allows us to group test-specific things.
- Module is underneath the code being tested
  - This allows us to test items that are ultimately private

---

# Writing a unit test

<Transclude src={writingUnitTest} emphasize="5" />

<Notes />

- Only compile the module when building tests
- Helps compile speed
- Prevents unintended things from being compiled in production

---

# Writing a unit test

<Transclude src={writingUnitTest} emphasize="7" />

<Notes />

- Rust's privacy rules allow a submodule to see everything from the parent

---

# Writing an integration test

**src/lib.rs**

import writingIntegrationTestLib from "rust:testing/writing-integration-test/src/lib.rs";
import writingIntegrationTestTest from "rust:testing/writing-integration-test/tests/basic.rs";

<Transclude src={writingIntegrationTestLib} />

---

# Writing an integration test

**src/lib.rs**

<Transclude src={writingIntegrationTestLib} emphasize="1[0+3]" />

<Notes />

- Must make the function (or method, or type, etc.) public

---

# Writing an integration test

**src/lib.rs**

<Transclude src={writingIntegrationTestLib} />

**tests/basic.rs**

<Transclude src={writingIntegrationTestTest} />

---

# Writing an integration test

**src/lib.rs**

<Transclude src={writingIntegrationTestLib} />

**tests/basic.rs**

<Transclude src={writingIntegrationTestTest} emphasize="1" />

<Notes />

- Import the public items to be able to use them

---

# Advanced test features

## Returning `Result`

import advancedFeatures from "rust:testing/advanced-features";

<Transclude src={advancedFeatures} focusOn="1+8" />

<Notes />

- This allows you to focus the test on the important part
- Any `Err` will count as a test failure

---

# Advanced test features

## Require that a panic occurs

<Transclude src={advancedFeatures} focusOn="10+5" />

<Notes />

- `expected` text must be in the panic message
- `expected` is optional

---

# Advanced test runner features

## Limiting which tests are run

```compiler-output
% cargo test not_a_test

running 0 tests

test result: ok. 0 passed; 0 failed; 0 ignored; 0 measured; 1 filtered out; finished in 0.00s
```

```compiler-output
% cargo test a_test

running 1 test
test a_test ... ok

test result: ok. 1 passed; 0 failed; 0 ignored; 0 measured; 0 filtered out; finished in 0.00s
```

<Notes />

- Does a simple substring match for name
- There are options for restricting to unit tests/integration tests/etc.

---

# Advanced test runner features

## Multi-threaded by default

```compiler-output
% cargo test -- --test-threads=1
```

<Notes />

- Note the intermediate `--`; separates Cargo arguments from the test binary

<Continue />

## Output of tests is captured by default

```compiler-output
% cargo test -- --nocapture
```

<Notes />

- Done to prevent useless interleaved output
- If a test fails, the captured output is printed at the end

---

# When to test

- Test never
- Test after
- Test before
- Test-driven development
- Test-driven design

<Notes />

- There's a continuum of testing
- "never" means that your customers are the one testing
- Test-before is writing up a bunch of tests before any code
- TD Development vs Design is all about how much you listen to the code

---

# Red, Green, Refactor

import redGreenRefactorImg from "./assets/testing/red-green-refactor.png";

<img
  className={styles.chunkyImage}
  src={redGreenRefactorImg}
  alt="Red, Green, Refactor Cycle"
/>

<Notes />

- Write a single failing test
- Do the simplest thing that will make the test pass
- Review the code (both test **and** production) for improvements
- Beware the malicious programmer!

---

# Red to green

- Write a test
  - Try to make it compile
- Write the production code
  - Make it compile
- Make test pass

<Notes />

- Test code might not be able to compile due to missing / incomplete production code
- This is not strictly linear; may have to iterate
- That iteration is the seed of the _design_ part of TDD

---

# Use `todo!()` / `unimplemented!` to sketch out code

import sketchWithUnimplemented from "rust:testing/sketch-with-unimplemented";

<Transclude src={sketchWithUnimplemented} focusOn="1+17" />

---

# Refactoring

<Quote caption="Refactoring: Improving the Design of Existing Code">
  Refactoring is a disciplined technique for restructuring an existing body of
  code, altering its internal structure without changing its external behavior.
</Quote>

<Notes />

- Notably this means that
  - you **cannot** refactor without some kind of test
  - you **cannot** add new functionality
- I bet you've worked with someone who has misused it to mean "changing stuff"

---

# Refactoring in a statically-typed language

import refactoring from "rust:testing/refactoring";

<Transclude src={refactoring} focusOn="1+7" />

---

# Refactoring in a statically-typed language

import refactoringRev1 from "rust:testing/refactoring?rev=1";

<Transclude src={refactoringRev1} focusOn="1+9" emphasize="1,3[13+7],4[9+2]" />

<Continue />

The compiler is your friend!

import refactoringRev1Stderr from "stderr:testing/refactoring?rev=1";

<Transclude src={refactoringRev1Stderr} lang="compiler-error" focusOn="1+17" />

<Notes />

- Any usage of a function or type counts as a _kind_ of test
- Reduces the need to have a bunch of edge case tests

---

# Exercise: Roman numeral converter

- Create a function that converts a number to the Roman numeral equivalent
- Repeat value to add it: 1 → I, 2 → II, 3 → III
- Smaller before larger to subtract: 4 → IV, 5 → V, 6 → VI
- I (1), V (5), X (10), L (50), C (100), D (500), M (1000)
- http://www.novaroma.org/via_romana/numbers.html

<Notes />

- Try to be as strict about TDD as you can
  - No production code until a test requires it
  - No refactoring while any test is red
- Optionally, try committing after every green and every refactor

---

# One potential solution

<Continue />

## Production code (1/2)

import exerciseRomanNumerals from "rust:testing/exercise-roman-numerals";

<Transclude src={exerciseRomanNumerals} focusOn="1+24" />

---

# One potential solution

## Test code (2/2)

<Transclude src={exerciseRomanNumerals} focusOn="26+28" />

---

# One potential solution

## Questions encountered

- What is the name of the function?
- What types does it take / return?
  - Started with `i32`, changed to `u32`, then to `u16`
- Around 5 or 6 I wanted to refactor - tests were red!
- Around 10, I started seeing a pattern and wanted to refactor again
- Wondered if `==` can be made into `>=`
- Refactored tests to be data-driven, using list of correct answers

<Notes />

- Can comment / stash in-progress tests or continue to add hacks to production code
- Tests offer an environment and opportunity to explore

---

# Making code testable

import makingItTestable from "rust:testing/making-it-testable";

<Transclude src={makingItTestable} focusOn="1+3" />

<Notes />

- There's a bug in this function — how would you add a test for it?

<Continue />

Testing something requires controlling it

---

# Pass in arguments

import makingItTestableRev1 from "rust:testing/making-it-testable?rev=1";

<Transclude src={makingItTestableRev1} focusOn="1+3" />

<Notes />

- Functions / methods that accept arguments
- Including constructor functions
- The best example of this kind of function is a pure function

<Continue />

import makingItTestableRev2 from "rust:testing/making-it-testable?rev=2";

<Transclude src={makingItTestableRev2} focusOn="1+3" />

---

# Watch out for hidden globals / singletons

- Time
- Database
- Network
- Filesystem
- Hardware

<Notes />

- You can have singletons without baking them into your code

---

# Use traits for dependency injection

import dependencyInjection from "rust:testing/dependency-injection";

<Transclude src={dependencyInjection} focusOn="1+11" />

<Notes />

- Traits are monomorphized, so no loss in production code
- The levels of abstraction are up to you
- Dependency injection frameworks exist and might have given this a bad name

---

# Exercise: Writing testable code

- Write a function that counts the words in Cargo.toml
- Prints the count to standard out

## Tips

- A word is whitespace-separated

---

# One potential solution

<Continue />

## Hard-to-test version

import exerciseTestableCode from "rust:testing/exercise-testable-code";

<Transclude src={exerciseTestableCode} />

---

# One potential solution

## Production code (1/2)

import exerciseTestableCodeRev1 from "rust:testing/exercise-testable-code?rev=1";

<Transclude src={exerciseTestableCodeRev1} focusOn="1+18" />

---

# One potential solution

## Test code (2/2)

<Transclude src={exerciseTestableCodeRev1} focusOn="20+23" />

<Notes />

- Assert is buried in the test struct
- Could use interior mutability to save args and assert later

---

# Conditional compilation as a big hammer

import conditionalCompilation from "rust:testing/conditional-compilation";

<Transclude src={conditionalCompilation} focusOn="1+15" />

<Notes />

- Not a fan of this style, but it's effective when making something testable that wasn't originally.

---

# Do unit tests actually test anything?

- Fast
- Highly localized
- Amount of code covered is smaller

<Notes />

- This can feel like "pushing peas around on the plate"

---

# What about integration tests?

- There is no substitute for integration tests
- Ensures that pieces work together
- You can choose what pieces are being integrated
  - Control outside that integration

---

# What about system tests?

- There is no substitute for system tests
- Ensures that the product does the right thing
- You will need to control _everything_

<Notes />

- Relatively expensive, slow, and brittle

---

# What's the right balance of tests?

<Continue />

- It depends
  - your code
  - your team
  - your risk tolerance
  - your time
  - your money

---

# Problems and solutions

- No tests? → Write tests
- Bad tests? → Write tests first
- Hard to write tests? → Inject dependencies

---

# Deeper testing topics

- Benchmarking
  - `cargo bench`
  - [Criterion](https://crates.io/crates/criterion)
- Fuzzing
  - [Cargo Fuzz](https://crates.io/crates/cargo-fuzz)
  - [AFL](https://crates.io/crates/afl)
- Property-based testing
  - [Proptest](https://crates.io/crates/proptest)
  - [Quickcheck](https://crates.io/crates/quickcheck)

---

<SlideLayout use={Splash} />

# <ChangeDeck deck="overview">Return</ChangeDeck>
