import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "./app/hooks";
import * as help from "./features/help/helpSlice";

import * as styles from "./Help.module.css";

type Key = [string[], string];

const KEYS: Key[] = [
  [["←", "↑", "PgUp"], "Previous slide"],
  [["→", "↓", "PgDn"], "Next slide"],
  [["Home"], "First slide"],
  [["End"], "Last slide"],
  [["a"], "Toggle aspect ratio"],
  [["c"], "Clone window"],
  [["g"], "Go to slide number"],
  [["p"], "Toggle presenter mode"],
  [["+", "="], "Increase font size"],
  [["-", "_"], "Decrease font size"],
  [[")"], "Reset font size"],
  [["?"], "Show help"],
];

const Keycap: React.FC<{ keycap: string }> = ({ keycap }) => (
  <span className={styles.keycap}>
    <code>{keycap}</code>
  </span>
);

const Key: React.FC<{ theKey: Key }> = ({ theKey }) => {
  const [keycaps, description] = theKey;

  return (
    <div className={styles.set}>
      <div className={styles.keycaps}>
        {keycaps.map((keycap, i) => (
          <Keycap key={i} keycap={keycap} />
        ))}
      </div>
      <span>{description}</span>
    </div>
  );
};

const Help: React.FC = () => {
  const enabled = useAppSelector((state) => state.help.enabled);
  const dispatch = useDispatch();
  const close = useCallback(() => dispatch(help.close()), [dispatch]);
  const dialog = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (!dialog.current) {
      return;
    }

    if (enabled) {
      dialog.current.showModal();
    } else {
      dialog.current.close();
    }
  }, [enabled]);

  return (
    <dialog ref={dialog} onClose={close} className={styles.dialog}>
      <form method="dialog" onReset={close} className={styles.container}>
        <button type="reset" className={styles.close}>
          ✖
        </button>

        <h1 className={styles.title}>Help</h1>

        <div className={styles.sets}>
          {KEYS.map((key, i) => (
            <Key key={i} theKey={key} />
          ))}
        </div>
      </form>
    </dialog>
  );
};

export default Help;
