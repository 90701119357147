import { createRoot } from "react-dom/client";

import App from "./App";
import { buildStore } from "./app/store";
import {
  forward,
  backward,
  setSlideIndex,
  togglePresenter,
  toggleAspectRatio,
  firstSlide,
  lastSlide,
  disablePrint,
  enablePrint,
} from "./features/deck/deckSlice";
import { setDeck } from "./features/route/routeSlice";
import { broadcast } from "./app/broadcast";
import { toggleMeta } from "./features/keyboard/keyboardSlice";
import { reset, decrease, increase } from "./features/window/windowSlice";
import { validateDeckType } from "./decks";
import * as help from "./features/help/helpSlice";
import { isAction } from "@reduxjs/toolkit";

function randomId(): string {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  return uint32.toString(16);
}

const SLIDESHOW_ID = randomId();

const url = new URL(window.location.toString());

if (!url.searchParams.has("id")) {
  url.searchParams.set("id", SLIDESHOW_ID);
  window.history.replaceState(null, "", url);
}

const CHANNEL_ID = url.searchParams.get("id") || SLIDESHOW_ID;

const channel = new BroadcastChannel(CHANNEL_ID);

const store = buildStore({ channel, window });
channel.addEventListener("message", (msg) => {
  if (Array.isArray(msg.data)) {
    for (const data of msg.data) {
      if (isAction(data)) {
        store.dispatch(data);
      }
    }
  }
});

const getDeckAndPageFromUrl = (url: URL) => {
  const deckTxt = validateDeckType(url.searchParams.get("deck"));
  store.dispatch(broadcast(setDeck(deckTxt)));

  const pageTxt = url.searchParams.get("page");
  const page = pageTxt ? parseInt(pageTxt, 10) : 1;
  store.dispatch(broadcast(setSlideIndex(page)));
};

getDeckAndPageFromUrl(url);

const app = document.getElementById("app");
const root = createRoot(app!);
root.render(<App store={store} />);

let runningTotal = 0;
window.addEventListener("keydown", (event) => {
  if (event.key === "Meta") {
    store.dispatch(toggleMeta());
  }

  if (event.altKey || event.ctrlKey || event.metaKey) {
    return;
  }

  switch (event.key) {
    case "ArrowRight":
    case "ArrowDown":
    case "PageDown": {
      store.dispatch(broadcast(forward()));
      break;
    }
    case "ArrowLeft":
    case "ArrowUp":
    case "PageUp": {
      store.dispatch(broadcast(backward()));
      break;
    }
    case "Home": {
      store.dispatch(broadcast(firstSlide()));
      break;
    }
    case "End": {
      store.dispatch(broadcast(lastSlide()));
      break;
    }
    case "a": {
      store.dispatch(broadcast(toggleAspectRatio()));
      break;
    }
    case "c": {
      window.open(window.location.toString(), "_blank", "popup=1");
      break;
    }
    case "g": {
      if (runningTotal !== 0) {
        store.dispatch(broadcast(setSlideIndex(runningTotal)));
      }
      break;
    }
    case "p": {
      store.dispatch(togglePresenter());
      break;
    }
    case "+":
    case "=": {
      store.dispatch(increase());
      break;
    }
    case "-":
    case "_": {
      store.dispatch(decrease());
      break;
    }
    case ")": {
      store.dispatch(reset());
      break;
    }
    case "?": {
      store.dispatch(help.toggle());
      break;
    }
  }

  const numericValue = Number(event.key);
  if (isNaN(numericValue)) {
    runningTotal = 0;
  } else {
    runningTotal = runningTotal * 10 + numericValue;
  }
});

window.addEventListener("beforeprint", (_event) => {
  store.dispatch(enablePrint());
});

window.addEventListener("afterprint", (_event) => {
  store.dispatch(disablePrint());
});

window.addEventListener("popstate", (_event) => {
  const url = new URL(window.location.toString());
  getDeckAndPageFromUrl(url);
});
