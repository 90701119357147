import Scaler from "./Scaler";

import * as styles from "./Splash.module.css";

interface SplashProps {
  children?: React.ReactNode;
}

const Splash: React.FC<SplashProps> = ({ children }) => (
  <div className={styles.outer}>
    <Scaler>
      <div className={styles.container}>{children}</div>
    </Scaler>
  </div>
);

export default Splash;
