import { createSlice } from "@reduxjs/toolkit";

export interface WindowState {
  fontSize: number;
}

const initialState: WindowState = {
  fontSize: 100,
};

export const windowSlice = createSlice({
  name: "window",
  initialState,
  reducers: {
    reset: (state) => {
      state.fontSize = initialState.fontSize;
    },
    increase: (state) => {
      state.fontSize += 5;
    },
    decrease: (state) => {
      state.fontSize -= 5;
    },
  },
});

export const { reset, increase, decrease } = windowSlice.actions;

export default windowSlice.reducer;
