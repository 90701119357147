import { SlideLayout } from "./Directives";
import ChangeDeck from "./ChangeDeck";
import TestPattern from "./TestPattern";

<SlideLayout use={TestPattern} />

---

# Agenda

- <ChangeDeck deck="intro">Introduction</ChangeDeck>
- <ChangeDeck deck="ownership">Ownership and borrowing</ChangeDeck>
- <ChangeDeck deck="option-and-result">Option and Result</ChangeDeck>
- <ChangeDeck deck="error-handling">Error handling</ChangeDeck>
- <ChangeDeck deck="iterators">Iterators</ChangeDeck>
- <ChangeDeck deck="types">Types</ChangeDeck>
- <ChangeDeck deck="concurrency">Concurrency</ChangeDeck>
- <ChangeDeck deck="async">Asynchronous programming</ChangeDeck>

---

# Resources

- [Rust website](https://www.rust-lang.org/)
- [Rust documentation](https://doc.rust-lang.org/)
- [Rust playground](https://play.rust-lang.org)
- [crates.io](https://crates.io)
- [docs.rs](https://docs.rs)
- [User's forum](https://users.rust-lang.org)
- [Discord](https://discord.gg/rust-lang)
- [Stack Overflow](https://stackoverflow.com/questions/tagged/rust)

---

# More topics

- <ChangeDeck deck="from-dynamic-languages">
    Coming from dynamic languages
  </ChangeDeck>
- <ChangeDeck deck="reliable-code-using-types">
    Reliable code using types
  </ChangeDeck>
- <ChangeDeck deck="testing">Testing</ChangeDeck>
- <ChangeDeck deck="strings-vs-bytes">Strings vs. bytes</ChangeDeck>
- <ChangeDeck deck="macros">Macros</ChangeDeck>
- <ChangeDeck deck="ffi">Unsafe and FFI</ChangeDeck>
- <ChangeDeck deck="embedded">Embedded</ChangeDeck>
