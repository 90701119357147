import { useContext } from "react";
import { SlidesContext, applyLayout, Slide } from "./Deck";

import * as styles from "./Print.module.css";

interface OneProps {
  slide: Slide;
}

const One: React.FC<OneProps> = ({ slide }) => {
  const slideContent = applyLayout(slide);
  const notes = slide.notes;

  return (
    <div className={styles.container}>
      {slideContent}
      <div className={styles.notes}>
        <h1>Speaker Notes</h1>
        {notes}
      </div>
    </div>
  );
};

const Print: React.FC = () => {
  const slides = useContext(SlidesContext);
  const slidesContent = slides.map((slide, key) => (
    <One slide={slide} key={key} />
  ));
  return <>{slidesContent}</>;
};

export default Print;
